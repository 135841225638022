import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    barChartDataDailyOrder: {
      labels: ['January', 'February', 'March', 'April'],
      datasets: [
        {
          label: 'Daily Orders',
          data: [120, 150, 180, 200],
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
        },
      ],
    },
    barChartDataDailyQuation: {
      labels: ['January', 'February', 'March', 'April'],
      datasets: [
        {
          label: 'Daily Quotations',
          data: [100, 140, 170, 190],
          backgroundColor: 'rgba(153, 102, 255, 0.6)',
        },
      ],
    },
    pieChartData: {
      labels: ['Type A', 'Type B', 'Type C'],
      datasets: [
        {
          data: [55, 25, 20],
          backgroundColor: [
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
          ],
        },
      ],
    },
  };
  
  const chartSlice = createSlice({
    name: 'chart',
    initialState,
    reducers: {
      updateBarChartDailyOrder(state, action) {
        state.barChartDataDailyOrder = action.payload;
      },
      updateBarChartDailyQuation(state, action) {
        state.barChartDataDailyQuation = action.payload;
      },
      updatePieChart(state, action) {
        state.pieChartData = action.payload;
      },
    },
  });
  export const {
    updateBarChartDailyOrder,
    updateBarChartDailyQuation,
    updatePieChart,
  } = chartSlice.actions;
  
  export default chartSlice.reducer;