import React, { useState } from "react";
import Modal from "react-modal";
import { set, cloneDeep, isEmpty, get } from "lodash";

import InputField from "@/components/InputField";
import Button from "@/components/Button";
import {
  INITIAL_ROLE_FORM_VALUE,
  ROLE_ERROR_MESSAGE_VALUES,
  ROLE_REQUIRED_FIELDS,
} from "./Constant";

Modal.setAppElement("#root");

const AddUserRolePopup = ({
  toggleRolePopup,
  isOpen,
  onAddRoleSubmit,
  selectedRow,
}) => {
  const [addRoleData, setAddRoleData] = useState(
    !isEmpty(selectedRow) ? selectedRow : INITIAL_ROLE_FORM_VALUE
  );
  const [errorMessages, setErrorMessages] = useState(ROLE_ERROR_MESSAGE_VALUES);
  const modeTxt = !isEmpty(selectedRow) ? "Edit" : "Add";

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedState = cloneDeep(addRoleData);
    set(updatedState, name, value);
    setAddRoleData(updatedState);
  };

  const handleSubmit = async () => {
    const newErrorMessages = {};
    const requiredFields = ROLE_REQUIRED_FIELDS;
    requiredFields.forEach((field) => {
      if (!get(addRoleData, field)) {
        newErrorMessages[field] = "This field is required.";
      } else {
        newErrorMessages[field] = "";
      }
    });

    setErrorMessages(newErrorMessages);
    if (Object.values(newErrorMessages).some((errorMsg) => errorMsg !== "")) {
      return;
    }

    const action = !isEmpty(selectedRow) ? "edit" : "";
    let payload = {};
    if (action === "edit") {
      payload = {
        name: addRoleData?.name,
        description: addRoleData?.description,
      };
    } else {
      payload = {
        ...addRoleData,
      };
    }
    onAddRoleSubmit(payload, action);
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleRolePopup}
        contentLabel="Example Modal"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        className="bg-white p-6 rounded shadow-lg"
      >
        <div className="">
          <span className="text-xl font-small pt-3.5 ">
            {modeTxt} User Role
          </span>
          <div className="grid grid-cols-2 gap-2 pt-3.5 ">
            <div className="mb-2">
              <InputField
                name="name"
                label="Role"
                placeholder="Enter Role"
                value={addRoleData.name}
                onChange={handleInputChange}
              />
              {errorMessages.name && (
                <p className="text-red-500 text-[12px] mt-1">
                  This field is required
                </p>
              )}
            </div>
            <div className="mb-2">
              <InputField
                label="Description"
                name="description"
                placeholder="Enter Description"
                value={addRoleData.description}
                onChange={handleInputChange}
              />
              {errorMessages.description && (
                <p className="text-red-500 text-[12px] mt-1">
                  This field is required
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="flex gap-2">
          <Button
            text="Submit"
            textColor="white"
            bgColor="[#1A91D1]"
            onClick={() => handleSubmit()}
          />
          <Button
            text="Cancel"
            textColor="Black"
            bgColor="White"
            onClick={() => toggleRolePopup()}
          />
        </div>
      </Modal>
    </div>
  );
};

export default AddUserRolePopup;
