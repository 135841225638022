import { isEmpty, map } from "lodash";

const USER_COLUMNS = [
  {
    Header: "Sr. No",
    accessor: "serialNumber",
    width: 5,
  },
  {
    Header: "Name",
    accessor: "fullName",
    width: 10,
  },
  {
    Header: "Username",
    accessor: "userId",
    width: 25,
  },
  {
    Header: "Role",
    accessor: "roleId",
    width: 25,
    Cell: ({ row }) => {
      const { roleDetails } = row.original;
      if (isEmpty(roleDetails)) {
        return "";
      }
      return <span>{`${map(roleDetails, "roleName").join(", ")}`}</span>;
    },
  },
  {
    Header: "Email",
    accessor: "contactMedium.email",
    width: 25,
  },
  {
    Header: "Contact",
    accessor: "contactMedium.phoneNumber",
    width: 25,
  },
];

const ROLE_COLUMNS = [
  {
    Header: "Sr. No",
    accessor: "serialNumber",
    width: 5,
  },
  {
    Header: "Role",
    accessor: "name",
    width: 10,
  },
  {
    Header: "Description",
    accessor: "description",
    width: 25,
  },
  {
    Header: "Status",
    accessor: "status",
    width: 25,
  },
];

const PERMISSION_COLUMNS = [
  {
    Header: "Serial No",
    accessor: "serialNumber",
    width: 2,
  },
  {
    Header: "Module Name",
    accessor: "moduleName",
    width: 10,
  },
  {
    Header: "Role Name",
    accessor: "roleName",
    width: 10,
  },
  {
    Header: "Access",
    accessor: "userAccess",
    width: 10,
    Cell: ({ row }) => {
      const { userAccess } = row.original;
      if (isEmpty(userAccess)) {
        return "";
      }

      return (
        <>
          <div className="block">
            Read: <strong>{userAccess.read ? "Yes" : "No"}</strong>
          </div>
          <div className="block">
            Write: <strong>{userAccess.write ? "Yes" : "No"}</strong>
          </div>
          <div className="block">
            Delete: <strong>{userAccess.delete ? "Yes" : "No"}</strong>
          </div>
        </>
      );
    },
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    width: 10,
  },
];

export { USER_COLUMNS, ROLE_COLUMNS, PERMISSION_COLUMNS };
