import { findIndex, isEmpty, omit, set } from "lodash";
import { useNavigate } from "react-router-dom";
//import AWS from 'aws-sdk';

export const isAdminLogged = () => {
  const data = JSON.parse(sessionStorage.getItem("user"));
  if (data) {
    return data.roleList.includes("Admin");
  }
  return false;
};

export const isLoginUser = () => {
  const navigate = useNavigate();
  if (
    !sessionStorage.getItem("authenticated") &&
    !window.location.href.includes("login") &&
    !window.location.href.includes("resetPassword")
  ) {
    navigate("/login");
  }
};

/** add serial number columns */
export let addSerialNumbersToList = async (dataList) => {
  return dataList.map((item, index) => {
    item["serialNumber"] = index + 1;
    return item;
  });
};

export const filterWorkOrder = (searchInput, filterData) => {
  if (!searchInput) {
    return [];
  }

  const searchText = searchInput.toLowerCase();
  return (
    filterData &&
    filterData.filter((order) => {
      const inquiryAttendedDate = order?.inquiryAttendedDate?.toLowerCase();
      const orderId = order?.orderId?.toLowerCase();
      const chassisNumber = order?.chassisNumber?.toLowerCase();
      const inquiryAttended = order?.inquiryAttended?.toLowerCase();
      const customerName = order?.customerName?.toLowerCase();
      const trailerType = order?.trailerType.productName?.toLowerCase();
      const status = order?.status?.toLowerCase();

      return (
        inquiryAttendedDate?.includes(searchText) ||
        orderId?.includes(searchText) ||
        chassisNumber?.includes(searchText) ||
        inquiryAttended?.includes(searchText) ||
        customerName?.includes(searchText) ||
        chassisNumber?.includes(searchText) ||
        trailerType?.includes(searchText) ||
        status?.includes(searchText)
      );
    })
  );
};

/** filter orderList base on customer name , quantity and product name function */
export const filterOrders = (searchInput, filterData) => {
  if (!searchInput) {
    return [];
  }

  const searchText = searchInput.toLowerCase();
  return (
    filterData &&
    filterData.filter((order) => {
      const customerName = order?.customerName?.toLowerCase();
      const productName = order?.trailerType?.productName?.toLowerCase();
      const quantity = order?.quantity?.toString();

      return (
        customerName?.includes(searchText) ||
        productName?.includes(searchText) ||
        quantity?.includes(searchText)
      );
    })
  );
};

/** filter orderList base on customer name , quantity and product name function */
export const filterCategoryData = (searchInput, filterData) => {
  if (!searchInput) {
    return [];
  }

  const searchText = searchInput.toLowerCase();
  return filterData.filter((order) => {
    const fieldsToSearch = ["name", "description"];
    return fieldsToSearch.some((field) =>
      order[field].toLowerCase().includes(searchText)
    );
  });
};

/** filter orderList base on customer name , quantity and product name function */
export const filterInventryData = (searchInput, filterData) => {
  if (!searchInput) {
    return [];
  }

  const searchText = searchInput.toLowerCase();
  return (
    filterData &&
    filterData.filter((order) => {
      const name = order?.name?.toLowerCase();
      const categoryName = order?.categoryName?.toLowerCase();
      const desc = order?.description?.toLowerCase();
      const originalPrice = order?.originalPrice?.toString();
      const stock = order?.stock?.toString();
      return (
        stock?.includes(searchText) ||
        categoryName?.includes(searchText) ||
        originalPrice?.includes(searchText) ||
        name?.includes(searchText) ||
        desc?.includes(searchText)
      );
    })
  );
};
/** filter orderList base on customer name , quantity and product name function */
export const filterInventryData1 = (searchInput, filterData) => {
  if (!searchInput) {
    return [];
  }

  const searchText = searchInput.toLowerCase();
  return (
    filterData &&
    filterData.filter((order) => {
      const name = order?.name?.toLowerCase();
      const code = order?.code?.toLowerCase();
      const categoryName = order?.categoryName?.toLowerCase();
      const desc = order?.description?.toLowerCase();
      const goodsType = order?.goodsType?.toLowerCase();
      console.log("materialType=" + goodsType);
      return (
        (goodsType ? goodsType.toString().trim() : "").includes(searchText) ||
        categoryName?.includes(searchText) ||
        name?.includes(searchText) ||
        desc?.includes(searchText) ||
        code?.includes(searchText)
      );
    })
  );
};

export const filterEnquiry = (searchInput, filterData) => {
  if (!searchInput) {
    return [];
  }

  const searchText = searchInput.toLowerCase();
  return (
    filterData &&
    filterData.filter((order) => {
      const customerName = order.firstName.toLowerCase();
      const transporterName = order.transporterName.toLowerCase();
      const typeOfTrailer = order.typeOfTrailer.toLowerCase();
      const phoneNumber = order.contactMedium.phoneNumber.toLowerCase();
      const email = order.contactMedium.email.toLowerCase();
      const location = order.location.toLowerCase();
      const competitor = order.competitor.toLowerCase();
      const quantity = order.quantity.toString();
      const salesPersonName = order?.salesPersonName?.toLowerCase();
      return (
        customerName.includes(searchText) ||
        transporterName.includes(searchText) ||
        typeOfTrailer.includes(searchText) ||
        phoneNumber.includes(searchText) ||
        email.includes(searchText) ||
        location.includes(searchText) ||
        quantity.includes(searchText) ||
        competitor.includes(searchText) ||
        salesPersonName?.includes(searchText)
      );
    })
  );
};

/** filter userList based on user full name , user name , role, email and phone number function */
export const filterUserData = (searchInput, filterData) => {
  if (!searchInput) {
    return [];
  }

  const searchText = searchInput.toLowerCase();
  return (
    filterData &&
    filterData.filter((user) => {
      const fullName = user?.fullName.toLowerCase();
      const userId = user?.userId.toLowerCase();
      const email = user?.contactMedium?.email.toString();
      const phoneNumber = user?.contactMedium?.phoneNumber.toString();
      const roleListName = user?.roleListName.toString();

      return (
        fullName.includes(searchText) ||
        userId.includes(searchText) ||
        email.includes(searchText) ||
        phoneNumber.includes(searchText) ||
        roleListName.includes(searchText)
      );
    })
  );
};

/** filter roleList based on role information */
export const filterRoleData = (searchInput, filterData) => {
  if (!searchInput) {
    return [];
  }

  const searchText = searchInput.toLowerCase();
  return (
    filterData &&
    filterData.filter((role) => {
      const roleName = role?.name.toLowerCase();
      const roleDescription = role?.description.toLowerCase();
      return (
        roleName.includes(searchText) || roleDescription.includes(searchText)
      );
    })
  );
};

/** filter permissionList based on permission information */
export const filterPermissionData = (searchInput, filterData) => {
  if (!searchInput) {
    return [];
  }

  const searchText = searchInput.toLowerCase();
  return (
    filterData &&
    filterData.filter((permission) => {
      const moduleName = permission?.moduleName.toLowerCase();
      const roleName = permission?.roleName.toLowerCase();
      const createdBy = permission?.createdBy.toLowerCase();

      return (
        moduleName.includes(searchText) ||
        roleName.includes(searchText) ||
        createdBy.includes(searchText)
      );
    })
  );
};

/** filter paymentList based on payment information */
export const filterPaymentData = (searchInput, filterData) => {
  if (!searchInput) {
    return [];
  }

  const searchText = searchInput.toLowerCase();
  return (
    filterData &&
    filterData.filter((item) => {
      const customerName = item?.customerName?.toLowerCase() || "";
      const orderId = item?.orderId?.toLowerCase() || "";
      const salesPersonId = item?.salesPersonId.toString() || "";
      const salesPersonName = item?.salesPersonName?.toString() || "";
      const closingAmount = item?.closingAmount?.toString() || "";
      const creditAmount = item?.creditAmount?.toString() || "";
      const debitAmount = item?.debitAmount?.toString() || "";

      return (
        customerName.includes(searchText) ||
        orderId.includes(searchText) ||
        salesPersonId.includes(searchText) ||
        salesPersonName.includes(searchText) ||
        closingAmount.includes(searchText) ||
        creditAmount.includes(searchText) ||
        debitAmount.includes(searchText)
      );
    })
  );
};

/** filter receiptList based on payment information */
export const filterReceiptData = (searchInput, filterData) => {
  if (!searchInput) {
    return [];
  }

  const searchText = searchInput.toLowerCase();
  return (
    filterData &&
    filterData.filter((item) => {
      const additionalInformation =
        item?.additionalInformation?.toLowerCase() || "";
      const amountInWords = item?.amountInWords?.toLowerCase() || "";
      const bankName = item?.bankName.toString() || "";
      const checkNumber = item?.checkNumber?.toString() || "";
      const modeOfPayment = item?.modeOfPayment?.toString() || "";
      const paidAmount = item?.paidAmount?.toString() || "";
      const receiptNumber = item?.receiptNumber?.toString() || "";
      const createdBy = item?.createdBy?.toString() || "";

      return (
        additionalInformation.includes(searchText) ||
        amountInWords.includes(searchText) ||
        bankName.includes(searchText) ||
        checkNumber.includes(searchText) ||
        modeOfPayment.includes(searchText) ||
        paidAmount.includes(searchText) ||
        receiptNumber.includes(searchText) ||
        createdBy.includes(searchText)
      );
    })
  );
};

export const filterBalanceInformation = (searchInput, filterData) => {
  if (!searchInput) {
    return [];
  }

  const searchText = searchInput.toLowerCase();
  return (
    filterData &&
    filterData.filter((item) => {
      const salesPersonId = item?.salesPersonId?.toString() || "";
      const balanceAmount = item?.balanceAmount?.toString() || "";
      const salesPersonName = item?.salesPersonName?.toString() || "";

      return (
        salesPersonId.includes(searchText) ||
        balanceAmount.includes(searchText) ||
        salesPersonName.includes(searchText)
      );
    })
  );
};

/** role wise module permission */
export const rolePermissionChecker = (moduleName, moduleList) => {
  if (!moduleName || isEmpty(moduleList)) {
    return false;
  }
  return findIndex(moduleList, { moduleName }) !== -1;
};

/* function to filter menu which user having access */
export const getVisibleMenus = (routes, moduleList) => {
  const visibleMenus = [];
  for (const route of routes) {
    const filteredMenus = [];
    const menus = route.pages;
    for (const menu of menus) {
      const haveParentMenuPermission = rolePermissionChecker(
        menu.allowedTo,
        moduleList
      );
      if (haveParentMenuPermission) {
        const parentMenu = omit(menu, "nestedRoutes");
        const nestedMenus = [];
        if (menu?.nestedRoutes) {
          for (const submenu of menu.nestedRoutes) {
            const haveChildMenuPermission = rolePermissionChecker(
              submenu.allowedTo,
              moduleList
            );
            if (haveChildMenuPermission) {
              nestedMenus.push(submenu);
            }
          }
        }
        set(parentMenu, "nestedRoutes", nestedMenus);
        filteredMenus.push(parentMenu);
      }
    }

    if (filteredMenus.length > 0) {
      visibleMenus.push({
        layout: route.layout,
        pages: filteredMenus,
      });
    }
  }

  return visibleMenus;
};
export const getDropdownOptions = async (response) => {
  if (!Array.isArray(response)) {
    throw new Error("Invalid input: response should be an array");
  }

  const options = {};
  const firstLetterToLower = (str) => {
    return str.charAt(0).toLowerCase() + str.slice(1).replace(/\s/g, "");
  };

  const specialCategories = {
    Axles: "axlesMake",
    Suspension: "suspensionMake",
    Tyres: "tyreMake",
  };
  var axlesType  = [],tyreType = [];
  response.forEach((category) => {
    if (category.mainCategory && category.productList) {
      options[firstLetterToLower(category.name)] = category.productList;
    } else if (category.subCategory && Array.isArray(category.subCategory)) {
      category.subCategory.forEach((subCategory) => {
        if (subCategory.productList && Array.isArray(subCategory.productList)) {
          // Add productList to type-specific options
          if (category.name === "Axles") {
          axlesType = subCategory.productList.map((product) => ({
              ...product,
              selected: product.productId === options.productId,
              productId: product.productId,
              productName: product.productName,
            }));
          // axlesType.push({list:subCategory.productList.map((product) => ({
          //     ...product,
          //     selected: product.productId === options.productId,
          //     productId: product.productId,
          //     productName: product.productName,
          //   })),name:subCategory.name});
            options["axlesType"] = axlesType;
          }
          if (category.name === "Suspension") {
            options["suspensionType"] = subCategory.productList.map(
              (product) => ({
                ...product,
                selected: product.productId === options.productId,
                productId: product.productId,
                productName: product.productName,
              })
            );
          }
          if (category.name === "Tyres") {
            if(subCategory.productList.length > 0){
           tyreType.push(subCategory.productList.map((product) => ({
              ...product,
              selected: product.productId === options.productId,
              productId: product.productId,
              productName: product.productName,
              makeName:subCategory.name
            })));
          
            //localStorage.setItem('tyreType', JSON.stringify(tyreType));

           
            }
          }
        }
      });
    }
   // options["tyreType"] = localStorage.getItem('tyreType');
    // Special categories processing
    Object.keys(specialCategories).forEach((key) => {
      if (
        category.name === key &&
        category.subCategory &&
        Array.isArray(category.subCategory)
      ) {
        console.log("Processing Special Category:", key);
        options[specialCategories[key]] = category.subCategory.map((sub) => ({
          id: sub.categoryId || "",
          name: sub.name || "",
          label: sub.name || "",
          productList: sub.productList || "",
        }));
      }
    });
  });
 
  console.log(
   "Processed Axles Options:", JSON.stringify(options.tyreType)
    );
  // Debugging logs
  console.log("Processed Axles Options:", options.axlesMake || []);
  console.log("Processed Suspension Options:", options.suspensionMake || []);
  console.log("Processed Tyre Options:", options.tyreMake || []);
  console.log("options", options);
  return options;
};
// export const uploadFileS3 = async (file,onProgress) => {
//   const region = 'ap-south-1';
//   const bucketName = 'autocraft-hub/quotation';
//   const bucket = 'autocraft-hub';
//   const accessKeyId = 'AKIAQE43KD66LLHWGXAE';
//   const secretAccessKey = '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';

//   // const s3 = new AWS.S3({
//   //     region,
//   //     accessKeyId: accessKeyId,
//   //     secretAccessKey: secretAccessKey,
//   //     signatureVersion: 'v4',
//   // });

//   const uniqueKey = `${Date.now()}_${Math.random().toString(36).substr(2, 9)}_${file.name}`;

//   const params = {
//       Bucket: bucketName,
//       Key: uniqueKey, // Randomized Key
//       Body: file,
//   };

//   await s3
//   .putObject(params)
//   .on("httpUploadProgress", function(evt)  {
//     let progress = parseInt((evt.loaded * 100) / evt.total);
//     onProgress(progress);
//     console.log(
//       `Uploading ${progress}%`
//     );
//   })
//   .promise();
//   const attachmentUrl = `https://${bucket}.s3.${region}.amazonaws.com/quotation/${uniqueKey}`;
//   return attachmentUrl;
// };

// export const getDropdownOptions = (response) => {
//   const options = {};

//   const firstLetterToLower = (str) => {
//     return str.charAt(0).toLowerCase() + str.slice(1).replace(/\s/g, "");
//   };

//   response.forEach((category) => {
//     // Handle productList for categories with products
//     if (category.productList && category.productList.length > 0) {
//       options[firstLetterToLower(category.name)] = category.productList.map(
//         (product) => ({
//           id: product.productId,
//           name: product.productName,
//         })
//       );
//     }
//     // Handle subcategories specifically for Axels
//     if (
//       category.name === "Axels" &&
//       category.subCategory &&
//       category.subCategory.length > 0
//     ) {
//       options.make = category.subCategory.map((sub) => ({
//         id: sub.categoryId,
//         name: sub.name,
//       }));
//     }
//     if (
//       category.name === "Suspension" &&
//       category.subCategory &&
//       category.subCategory.length > 0
//     ) {
//       options.suspensionMake = category.subCategory.map((sub) => ({
//         id: sub.categoryId,
//         name: sub.name,
//       }));
//     }
//     if (
//       category.name === "TYRE" &&
//       category.subCategory &&
//       category.subCategory.length > 0
//     ) {
//       options.tyreMake = category.subCategory.map((sub) => ({
//         id: sub.categoryId,
//         name: sub.name,
//       }));
//     }
//   });
//   if (options.make) {
//     options.make.forEach((subcategory) => {
//       console.log(`Subcategory Name: ${subcategory.name}`);
//       console.log(`Subcategory ID: ${subcategory.id}`);
//       // Populate the dropdown for "Make"
//     });
//   }
//   if (options.suspensionMake) {
//     options.suspensionMake.forEach((subcategory) => {
//       console.log(`Subcategory Name: ${subcategory.name}`);
//       console.log(`Subcategory ID: ${subcategory.id}`);
//       // Populate the dropdown for "Make"
//     });
//   }
//   if (options.tyreMake) {
//     options.tyreMake.forEach((subcategory) => {
//       console.log(`Subcategory Name: ${subcategory.name}`);
//       console.log(`Subcategory ID: ${subcategory.id}`);
//       // Populate the dropdown for "Make"
//     });
//   }
//   console.log("Ha meri jaan", options);
//   console.log("Axles Options:", options.make);
//   console.log("Suspension Options:", options.suspensionMake);
//   console.log("Tyre Options:", options.tyreMake);

//   return options;
// };

// export const getDropdownOptions = async (response) => {
//   const options = {};
//   const firstLetterToLower = (str) => {
//     return str.charAt(0).toLowerCase() + str.slice(1).replace(/\s/g, "");
//   };
//   console.log("middle data:", response);
//   response.forEach((category) => {
//     if (category.mainCategory && category.productList) {
//       options[firstLetterToLower(category.name)] = category.productList;
//       console.log(category.subCategory + " data");
//     } else if (category.subCategory) {
//       category.subCategory.forEach((subCategory) => {
//         if (subCategory.productList) {
//           options[firstLetterToLower(subCategory.name)] =
//             subCategory.productList;
//         }
//       });
//     }
//   });

//   return options;
// };
// export const getDropdownOptions = async (response) => {
//   const options = {};
//   const firstLetterToLower = (str) =>
//     str.charAt(0).toLowerCase() + str.slice(1).replace(/\s/g, "");

//   response.forEach((category) => {
//     if (category.subCategory) {
//       options[firstLetterToLower(category.name)] = category.name;
//     }
//     console.log(category.subCategory + " data");
//     if (category.subCategory) {
//       category.subCategory.forEach((subCategory) => {
//         if (subCategory.name) {
//           options[firstLetterToLower(category.name)] = [
//             ...(options[firstLetterToLower(category.name)] || []),
//             ...subCategory.productList,
//           ];
//         }
//       });
//     }
//   });

//   console.log("Processed Options:", options);
//   return options;
// };

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};
