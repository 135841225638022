import React, { useState } from "react";
import { AC_TRAILER,AC_GROUP } from "../../utils/logo";
import { func } from "prop-types";
import { isObject } from "lodash";

const CostSheet = () => {
  const [data, setData] = useState([
    {
      section: "A. RM Steel Weight (Net Weight Of Trailer)",
      rows: [
        {
          itemName: "40 FT FLAT BED Only Container Application", // Item Name
          unit: "40 FT FLAT BED Only Container Application",
          qty: 1,
          rate: "-",
          amount: "-",
          description:  [
            {label:"grossWt",unit:'kg'}, 
            {label:"scrapWt",unit:'kg'}, 
            {label:"netWt",unit:'kg'},
          ],
          editable: true, // Editable for this section
          netCost: 0, // Net Cost (Calculated)
        },
        // {
        //   itemName: "40 FT FLAT BED Only Container Application (Type B)", // Item Name
        //   unit: "40 FT FLAT BED Only Container Application (Type B)",
        //   qty: 2,
        //   rate: "-",
        //   amount: "-",
        //   editable: true, // Editable for this section
        //   description:  {
        //     grossWt: 5000, 
        //     scrapWt: 0,
        //     netWt: 5000,
        //   },
        //   netCost: 0, // Net Cost (Calculated)
        // },
      ],
    },
    {
      section: "B. BOP Items Description",
      rows: [
        {
          itemName: "Axles", // Item Name
          description: "Axle (13 Ton)",
          unit: "Piece",
          qty: 3,
          rate: 55500,
          amount: 166500,
          editable: true,
        },
        {
          itemName: "Suspension", // Item Name
          description: "Suspension (54 Ton)",
          unit: "Piece",
          qty: 1,
          rate: 202125,
          amount: 202125,
          editable: true,
        },
        {
          itemName: "Tyres", // Item Name
          description: "Tyre MRF S1M4",
          unit: "Piece",
          qty: 12,
          rate: 18030,
          amount: 216360,
          editable: true,
        },
        {
          itemName: "BOP Steel Plate", // Item Name
          description: "BOP Steel Plate",
          unit: "Ton",
          qty: 2,
          rate: 25000,
          amount: 50000,
          editable: true,
        },
        {
          itemName: "BOP Weld Material", // Item Name
          description: "BOP Weld Material",
          unit: "Kg",
          qty: 100,
          rate: 400,
          amount: 40000,
          editable: true,
        },
      ],
    },
    {
      section: "C. Conversion Cost",
      rows: [
        { itemName: "Fabrication Cost",description:"To Be Consider On 6/Kg On Net Wt", unit:'Kg', qty: 5000, rate: 14, amount: 70000, editable: true },
        { itemName: "Plasma Cutting",description:"  PLASMA _   COST", qty: 1, rate: 3000,unit:'Unit', amount: 3000, editable: true },
        { itemName: "Bending Cost",description:"Bending machine cost 1.5 /kg", qty: 2000,unit:'Unit', rate: 1.5, amount: 3000, editable: true },
        { itemName: "Assembly Axle",description:"Axle", qty: 1, rate: 4800, amount: 4800,unit:'Nos', editable: true },
        { itemName: "Painting Cost",description:"Tyres ( For 3 Axle 12 Nos And For 2Axle 8 Nos )",unit:"Nos", qty: 1, rate: 4800, amount: 4800, editable: true },
      ],
    },
  ]);

  const handleEdit = (sectionIndex, rowIndex, field, value) => {
    const updatedData = [...data];
    const row = updatedData[sectionIndex].rows[rowIndex];
    row[field] = value;

    if (field === "qty" || field === "rate") {
      row.amount =
        row.rate !== "-" && !isNaN(row.qty) && !isNaN(row.rate)
          ? row.qty * row.rate
          : 0;
    }

    setData(updatedData);
  };

  return (
    <div className="p-4 bg-gray-100 min-h-screen">
      <div className="overflow-x-auto bg-white shadow-md rounded-lg p-6">
      <div className="flex justify-center items-center  mb-6 w-3/4">
       <div className="flex-1">
        <img
          src={AC_TRAILER}
          alt="Autocraft Trailer Logo"
          className="h-16"
        />
        </div>
        <div className="flex-1">
         <h1 className="text-9xl font-bold uppercase text-gray-800">
         Trailer Cost Sheet
      </h1>
      </div>
      <div className="flex-1" >
          <img src={AC_GROUP} className="ml-52" />
          </div>
      </div>

      {/* Title */}
     
        <table className="w-full table-auto border-collapse border border-gray-400">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-400 px-2 py-1 text-left">Section</th>
              <th className="border border-gray-400 px-2 py-1 text-left">Item Name</th>
              <th className="border border-gray-400 px-2 py-1 text-left">Description</th>
              <th className="border border-gray-400 px-2 py-1 text-left">Unit</th>
              <th className="border border-gray-400 px-2 py-1 text-right">Qty</th>
              <th className="border border-gray-400 px-2 py-1 text-right">Rate</th>
              <th className="border border-gray-400 px-2 py-1 text-right">Amount</th>
            </tr>
          </thead>
          <tbody>
            {data.map((section, sectionIndex) => (
              <>
                {/* Section Header */}
                <tr key={`section-${sectionIndex}`} className="bg-gray-200">
                  <td
                    className="border border-gray-400 px-2 py-1 font-semibold"
                    colSpan="12" align="left"
                  >
                    {section.section}
                  </td>
                </tr>

                {/* Section Rows */}
                {
                section.rows.map(function(row, rowIndex) {
                  const isObject =   
                  Array.isArray(row.description);
                  //(typeof row.description === 'object' && row.description !== null); 
                  return (
                  <tr key={`row-${sectionIndex}-${rowIndex}`}>
                    <td className="border border-gray-400 px-2 py-2"></td>
                    <td className="border border-gray-400 px-2 py-2">{row.itemName}</td>
                    <td  className={`border border-gray-400 px-2 py-2 `}
                    colSpan={isObject ? 6:null}
                    >{
     isObject  ? <table>
                      {row.description.map((row1,rowIndex1) =>(<tr><td width={100}>{row1.label}</td><td><input   className="w-full text-right px-2 py-1 border border-gray-300" /></td><td width={100}>{row1.unit}</td>  
                    <td className="border border-gray-400 px-4 py-2 text-right">
                      {row1.editable ? (
                        <input
                          type="number"
                          className="w-full text-right px-2 py-1 border border-gray-300"
                          value={row.qty}
                          onChange={(e) =>
                            handleEdit(
                              sectionIndex,
                              rowIndex,
                              "qty",
                              parseFloat(e.target.value) || 0
                            )
                          }
                        />
                      ) : (
                        row.qty
                      )}
                    </td>
                    <td className="border border-gray-400 px-4 py-2 text-right">
                      {row1.editable ? (
                        <input
                          type="number"
                          className="w-full text-right px-2 py-1 border border-gray-300"
                          value={row.rate}
                          onChange={(e) =>
                            handleEdit(
                              sectionIndex,
                              rowIndex,
                              "rate",
                              parseFloat(e.target.value) || 0
                            )
                          }
                        />
                      ) : (
                        row1.rate
                      )}
                    </td>
                    <td width={100} className="border border-gray-400 px-4 py-2 text-right">
                      {row1.amount}
                    </td> </tr>))}
                    </table>:
                    row.description}</td>
                    {
                        isObject ? <></> :<><td width={100} className="border border-gray-400 px-4 py-2">{row.unit}</td>
                    
                    
                    <td className="border border-gray-400 px-4 py-2 text-right">
                      {row.editable ? (
                        <input
                          type="number"
                          className="w-full text-right px-2 py-1 border border-gray-300"
                          value={row.qty}
                          onChange={(e) =>
                            handleEdit(
                              sectionIndex,
                              rowIndex,
                              "qty",
                              parseFloat(e.target.value) || 0
                            )
                          }
                        />
                      ) : (
                        row.qty
                      )}
                    </td>
                    <td className="border border-gray-400 px-4 py-2 text-right">
                      {row.editable ? (
                        <input
                          type="number"
                          className="w-full text-right px-2 py-1 border border-gray-300"
                          value={row.rate}
                          onChange={(e) =>
                            handleEdit(
                              sectionIndex,
                              rowIndex,
                              "rate",
                              parseFloat(e.target.value) || 0
                            )
                          }
                        />
                      ) : (
                        row.rate
                      )}
                    </td>
                    <td width={100} className="border border-gray-400 px-4 py-2 text-right">
                      {row.amount}
                    </td></>}
                  </tr>
                )}
                )}
              </>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CostSheet;
