import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Bar, Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import {
  updateBarChartDailyOrder,
  updatePieChart,
} from '../../store/reducers/reducer_dashboard';

const Dashboard = () => {
  const dispatch = useDispatch();
  const {
    barChartDataDailyOrder,
    barChartDataDailyQuation,
    pieChartData,
  } = useSelector((state) => state.chart);

  const handleUpdateData = () => {
    dispatch(
      updateBarChartDailyOrder({
        labels: ['May', 'June', 'July', 'August'],
        datasets: [
          {
            label: 'Updated Daily Orders',
            data: [200, 250, 300, 350],
            backgroundColor: 'rgba(255, 99, 132, 0.6)',
          },
        ],
      })
    );

    dispatch(
      updatePieChart({
        labels: ['Type D', 'Type E', 'Type F'],
        datasets: [
          {
            data: [40, 35, 25],
            backgroundColor: [
              'rgba(54, 162, 235, 0.6)',
              'rgba(75, 192, 192, 0.6)',
              'rgba(255, 206, 86, 0.6)',
            ],
          },
        ],
      })
    );
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1 className="text-6xl font-bold mb-6">Dashboard</h1>
      <div className="flex flex-wrap justify-center">
        <div className="mx-4" style={{ marginBottom: '40px', height: '300px', width: '300px' }}>
          <h2 className="text-lg font-semibold mb-2">Daily Orders</h2>
          <Bar data={barChartDataDailyOrder} options={{ responsive: true, maintainAspectRatio: false }} />
        </div>
        <div className="mx-4" style={{ marginBottom: '40px', height: '300px', width: '300px' }}>
          <h2 className="text-lg font-semibold mb-2">Daily Quotations</h2>
          <Bar data={barChartDataDailyQuation} options={{ responsive: true, maintainAspectRatio: false }} />
        </div>
        <div className="mx-4" style={{ marginBottom: '40px', height: '300px', width: '300px' }}>
          <h2 className="text-lg font-semibold mb-2">Most Monthly Sales Type of Trailer</h2>
          <Pie data={pieChartData} options={{ responsive: true, maintainAspectRatio: false }} />
        </div>
      </div>
      <button
        onClick={handleUpdateData}
        className="bg-blue-500 text-white px-4 py-2 mt-6"
      >
        Update Data
      </button>
    </div>
  );
};

export default Dashboard;
