import React, { useState, useEffect } from 'react';
import InputField from '../../components/InputField/InputField';
import Dropdown from '../../components/Dropdown/Dropdown';
import { productInventoryApi,productInventoryType ,categoryApi, organization} from "../../utils/API";
import { toast } from "react-toastify";
import { APIService } from "@/services";
import Button from "@/components/Button";
import { useNavigate, useLocation } from "react-router-dom";
import { ADD_INVENTORY_PRODUCT_INITIAL_FORM_VALUE } from "./constants";
//import { Button } from 'react-bootstrap';

function SupplyMaster() {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [materialTypeList,setMaterialTypeList] = useState([]);
    const [organisationList,setOrganisationList] = useState([]);
    const [categoryNameList, setCategoryNameList] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState(
        location.state
            ? { name: location.state.selectedRow.categoryName, id: location.state.selectedRow.categoryId, label: location.state.selectedRow.categoryName }
            : []
    );

    const defaultRow = location.state ? location.state.selectedRow : ADD_INVENTORY_PRODUCT_INITIAL_FORM_VALUE;
//console.log("companyIdeneialfdId"+defaultRow.companyIdentifierId);
    const [formValues, setFormValues] = useState({
        code: defaultRow.code,
        name: defaultRow.name,
        make:defaultRow.make,
        category: selectedOptions,
        description: defaultRow.description,
        unit: { label: defaultRow.purchaseUnit, name: defaultRow.purchaseUnit },
        organization: { label: defaultRow.companyIdentifier, name: defaultRow.companyIdentifier,id:defaultRow.companyIdentifierId },
        goodsType: { label: defaultRow.goodsType, name: defaultRow.goodsType,id:defaultRow.id },
        ratePerUnit: defaultRow.perUnitRate,
        stockType: { label: defaultRow.stockType, name: defaultRow.stockType },
        estimatedLifeValue: defaultRow.estimatedLifeValue,
        reOrderLevel: defaultRow.reOrderLevel,
        is_scrap_goods: { label: defaultRow.isScrapGoods === "No" ? "no" : "yes", name: defaultRow.isScrapGoods === "No" ? "no" : "yes" },
        remark: defaultRow.remark,
        batch_number: defaultRow.hsnCode,
        additionalInfo: defaultRow.additionalInfo || '',
        categoryId: defaultRow.categoryId || '',
        categoryName: defaultRow.categoryName || '',
    });

    const modifiedData = (data) => data.map(item => ({
        name: item.name,
        label: item.name,
        id: item.id
    }));
    const modifiedDataInventroyType = (data) => data.map(item => ({
        name: item.name,
        label: item.name,
        id: item.code
    }));

    const modifiedDataCompanyList = (data) => data.map(item => ({
        name: item.organizationName,
        label: item.organizationName,
        id: item.id
    }));

    const getCategoryFilterData = async (name) => {
      
        setIsLoading(true);
        try {
            let length=0;
            let response ;
            if(name != ''){
            let query = categoryApi.getCategoryByName(name);
             console.log(`qurey=${query}`);
             response = await APIService.get(query);
             console.log(response);
             length =  response.categoryList.length;
            
            }
           
            if(length == 0){
                response = await APIService.get(categoryApi.filterCategory);
                length= response.categoryList.length;
              
            }
            if( length > 1  ){
                console.log(response.categoryList);
                let filterdCategory  = Array.from(response.categoryList).filter(function(e){
                
                    let value =(e['subCategories'] == undefined);
                    console.log(e['subCategories']);
                    return value;
                });
                
                setCategoryNameList(modifiedData(filterdCategory) || []);
            }else{
                console.log("insubcateogries");
                setCategoryNameList(modifiedData(response.categoryList[0].subCategories) || []);
            }
            if(location.state){
                let categoryName = "";
                let category = Array.from(response.categoryList).find((v)=>v.id == location.state.selectedRow.categoryId);
                if(category != undefined){
                    categoryName = category['name'];
                }
                let obj = {name:categoryName,id:location.state.selectedRow.categoryId,label:categoryName};
                handleOptionSelect("category", obj);
            }
            else{
                handleOptionSelect("category", {label:"Select",name:"Select"});
            }
        } catch (error) {
            console.log("error"+error);
            toast.error("Failed to load categories");
        } finally {
            setIsLoading(false);
        }
    };

    const navigate = useNavigate();

    useEffect(() => {
        getCategoryFilterData('');
        getOrganisationList();
        getProductInventoryType();
    }, [false]);

    const handleInputChange = function(name, value) {   
        setFormValues(prevValues => ({ ...prevValues, [name]: value }));
    };

    const handleOptionSelect = function(name, selectedOption)  {
        if(name == 'goodsType')
        getCategoryFilterData(selectedOption['name']);
        if(name == "category"){
            handleInputChange('make',selectedOption['name']);
        }
        setFormValues(prevValues => ({ ...prevValues, [name]: selectedOption }));
    };
    const getProductInventoryType = async ()=>{
        let response;
        response   = await APIService.get(productInventoryType.get);
        if (response && response.inventoryTypeList) { 
        console.log(response.inventoryTypeList);
          setMaterialTypeList(modifiedDataInventroyType(response.inventoryTypeList));
        }else{
            console.error("invalid response");
        }
          
    }

    const getOrganisationList = async ()=>{
        let response;
        response   = await APIService.get(organization.get);
        console.log(response);
        if(response && response.organizationList){
         let organisationList = modifiedDataCompanyList(response.organizationList);
            setOrganisationList(organisationList);
            if(defaultRow.id == undefined)
            handleOptionSelect('organization',organisationList[3]);  
         
        }
        // if (response && response.inventoryTypeList) { 
        // console.log(response.inventoryTypeList);
        //   setMaterialTypeList(modifiedDataInventroyType(response.inventoryTypeList));
        // }else{
        //     console.error("invalid response");
        // }
          
    }

    const onAddInventoryProductSubmit = async (data, action) => {
        let payload = {
            additionalInfo: data['additionalInfo'],
            conversionRatio: 0,
            description: data['description'],
            estimatedLifeValue: parseInt(data['estimatedLifeValue']),
            goodsType: data['goodsType']['label'],
            hsnCode: data['batch_number'],
            code: data['code'],
            companyIdentifier:data['organization']['name'],
            companyIdentifierId:data['organization']['id'],
            materialType:data['goodsType']['label'],
            materialCode:data['goodsType']['id'],
            isScrapGoods: data['is_scrap_goods']['label'].toLowerCase() === 'yes',
            name: data['name'],
            perUnitRate: parseInt(data['ratePerUnit']),
            purchaseUnit: data['unit']['label'],
            reOrderLevel: parseInt(data['reOrderLevel']),
            remark: data['remark'],
            salesUnit: data['unit']['label'],
            stockType: data['stockType']['label'],
            type: "GOODS",
            categoryId: data['category']['id'],
            categoryName: data['category']['label'],
            make:data['make']
            
        };

        if (defaultRow.id !== undefined) {
            payload['code'] = data['code'];
            action = "patch";
        }

        let response;
        try {
            if (action === "post") {
                response = await APIService.post(productInventoryApi.post, payload);
            } else if (action === "patch") {
                response = await APIService.patch(`${productInventoryApi.patch}${defaultRow.id}`, JSON.stringify(payload));
            }

            if (response.status === 200 || response.status === 201) {
                toast.success("Inventory Product saved successfully");
                navigate(-1);
            } else {
                throw new Error('Error saving data');
            }
        } catch (error) {
            console.error("Error while saving category:", error);
            toast.error("Error while saving category!");
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onAddInventoryProductSubmit(formValues, 'post');
    };

    return (
        <form className="p-4 bg-white shadow-md rounded-lg" >
            <h2 className="text-2xl font-bold mb-6 text-center">Inventory Master Form</h2>

            <div className="grid grid-cols-2 gap-4 mb-4">
                {/* {defaultRow.id !== undefined && ( */}
                   
                {/* )} */}
                <Dropdown
                    name="organization"
                    label="Company Code"
                    selectedOption={formValues.organization}
                    options={organisationList}
                    required={true} // Marked as required
                    onOptionSelect={(selectedOption) => handleOptionSelect("organization", selectedOption)}
                />
                 <InputField
                        name="code"
                        label="Material Code"
                        placeholder="Material Code"
                        maxLength={12}
                        // required={true}
                        // disabled={true}
                        value={formValues.code}
                        onChange={(e) => handleInputChange("code", e.target.value)}
                    />
                <InputField
                    name="name"
                    label="Material Short Name"
                    placeholder="Material Short Name"
                    required={true} // Marked as required
                    maxLength={40}
                    value={formValues.name}
                    onChange={(e) => handleInputChange("name", e.target.value)}
                />
                <InputField
                    name="description"
                    label="Material Long Description"
                    placeholder="Material Long Description"
                    required={true} // Marked as required
                    maxLength={250}
                    value={formValues.description}
                    onChange={(e) => handleInputChange("description", e.target.value)}
                />
                <Dropdown
                    name="goodsType"
                    label="Material Type"
                    selectedOption={formValues.goodsType}
                    options={materialTypeList}
                    required={true} // Marked as required
                    onOptionSelect={(selectedOption) => handleOptionSelect("goodsType", selectedOption)}
                />
                       <Dropdown
                    name="category"
                    label="Category"
                    selectedOption={formValues.category}
                    options={categoryNameList}
                    required={true} // Marked as required
                    onOptionSelect={(selectedOption) => handleOptionSelect("category", selectedOption)}
                />

            <InputField
                    name="make"
                    label="Make"
                    placeholder="Enter Make"
                    required={true} // Marked as required
                    maxLength={250}
                    value={formValues.make}
                    onChange={(e) => handleInputChange("make", e.target.value)}
                />
                <Dropdown
                    name="unit"
                    label="Unit of Measurement (UoM)"
                    selectedOption={formValues.unit}
                    options={[
                        { label: "KG", name: "KG" },
                        { label: "CMT", name: "CMT" },
                        { label: "LTR", name: "LTR" },
                        { label: "NOS", name: "NOS" },
                        { label: "SET", name: "SET" }
                    ]}
                    required={true} // Marked as required
                    onOptionSelect={(selectedOption) => handleOptionSelect("unit", selectedOption)}
                />
                
                <InputField
                    name="ratePerUnit"
                    label="Unit Rate (INR)"
                    placeholder="Price Per Unit"
                    required={true} // Marked as required
                    value={formValues.ratePerUnit}
                    onChange={(e) => handleInputChange("ratePerUnit", e.target.value)}
                />
                <Dropdown
                    name="stockType"
                    label="Stock Type"
                    selectedOption={formValues.stockType}
                    options={[
                        { label: "In Stock", name: "in_stock" },
                        { label: "Out of Stock", name: "out_of_stock" },
                        { label: "Pre-order", name: "pre_order" }
                    ]}
                    required={false}
                    onOptionSelect={(selectedOption) => handleOptionSelect("stockType", selectedOption)}
                />
                <InputField
                    name="estimatedLifeValue"
                    label="Estimated Life Value"
                    placeholder="Estimated Life Value"
                    required={false}
                    value={formValues.estimatedLifeValue}
                    onChange={(e) => handleInputChange("estimatedLifeValue", e.target.value)}
                />
                <InputField
                    name="reOrderLevel"
                    label="Reorder Level"
                    placeholder="Reorder Level"
                    required={false}
                    value={formValues.reOrderLevel}
                    onChange={(e) => handleInputChange("reOrderLevel", e.target.value)}
                />
                <Dropdown
                    name="is_scrap_goods"
                    label="Is Scrap Goods"
                    selectedOption={formValues.is_scrap_goods}
                    options={[
                        { id: 1, label: "Yes", name: "yes" },
                        { id: 2, label: "No", name: "no" }
                    ]}
                    required={true} // Marked as required
                    onOptionSelect={(selectedOption) => handleOptionSelect("is_scrap_goods", selectedOption)}
                />
                <InputField
                    name="batch_number"
                    label="HSN Code"
                    placeholder="Batch Number"
                    required={true} // Marked as required
                    value={formValues.batch_number}
                    onChange={(e) => handleInputChange("batch_number", e.target.value)}
                />
                <InputField
                    name="additionalInfo"
                    label="Additional Information"
                    placeholder="Additional Information"
                    value={formValues.additionalInfo}
                    onChange={(e) => handleInputChange("additionalInfo", e.target.value)}
                />
         
            </div>

            {/* Buttons */}
            <div className='flex justify-center items-center space-x-4 mb-4'>
                <Button text="Back" onClick={() => navigate(-1)} />
                <Button 
        text="Submit" 
        textColor="white" 
        bgColor="[#39ACE4]" 
        onClick={handleSubmit} 
    />
            </div>
        </form>
    );
}

export default SupplyMaster;

// import React from 'react';
// import { useState,useEffect } from 'react';
// import InputField from '../../components/InputField/InputField';
// import Dropdown from '../../components/Dropdown/Dropdown';
// import { productInventoryApi,categoryApi } from "../../utils/API";
// import { toast } from "react-toastify";
// import { APIService } from "@/services";
// import { isEmpty } from "lodash";
// import Select from "react-select";

// import { useNavigate, useLocation } from "react-router-dom";
// import { ADD_INVENTORY_PRODUCT_INITIAL_FORM_VALUE } from "./constants";

// function SupplyMaster() {
//     const location = useLocation();
//     const [isLoading, setIsLoading] = useState(false);
//     const [categoryNameList, setCategoryNameList] = useState([]);
//     const [selectedOptions, setSelectedOptions] = useState(location.state != null ? {name:location.state.selectedRow.categoryName, id: location.state.selectedRow.categoryId, label: location.state.selectedRow.categoryName } : []);
//     const defaultRow = location.state != null ? location.state.selectedRow : ADD_INVENTORY_PRODUCT_INITIAL_FORM_VALUE;
//     const [formValues, setFormValues] = useState({
//         code: defaultRow.code,
//         name: defaultRow.name,
//         category:selectedOptions,
//         description: defaultRow.description,
//         unit: { label: defaultRow.purchaseUnit, name: defaultRow.purchaseUnit },
//         goodsType: { label: defaultRow.goodsType, name: defaultRow.goodsType },
//         ratePerUnit: defaultRow.perUnitRate,
//         stockType: { label: defaultRow.stockType, name: defaultRow.stockType },
//         estimatedLifeValue: defaultRow.estimatedLifeValue,
//         reOrderLevel: defaultRow.reOrderLevel,
//         is_scrap_goods: { label: defaultRow.isScrapGoods == "No" ? "no" : "yes",name:defaultRow.isScrapGoods == "No" ? "no" : "yes" }, // Is Scrap Goods dropdown
//         remark: defaultRow.remark,
//         batch_number: defaultRow.hsnCode,
//         additionalInfo: defaultRow.additionalInfo || '',
//         categoryId: defaultRow.categoryId || '',
//         categoryName: defaultRow.categoryName || '',
//     });
//     const modifiedData = (data) => {
//         return data.map(item => ({
//             name: item.name,
//             label: item.name,
//             id:item.id
//         }));
//     };

//     const getCategoryFilterData = async () => {
//         const response = await APIService.get(categoryApi.filterCategory);
//         setCategoryNameList(modifiedData(response.categoryList) || []);
//         setIsLoading(false);
//     };
//     const navigate = useNavigate();

//     useEffect(() => {
//        // getProduct();
//         getCategoryFilterData();
//     });

//     const handleInputChange = (name, value) => {
//         setFormValues((prevValues) => ({
//             ...prevValues,
//             [name]: value,
//         }));
//     };
//     const handleOptionSelectCategory = (selectedOptions) => {
//         setSelectedOptions(selectedOptions);
//     };

//     const handleOptionSelect = (name, selectedOption) => {
//         setFormValues((prevValues) => ({
//             ...prevValues,
//             [name]: selectedOption,
//         }));
//     };

//     const onAddInventoryProductSubmit = async (data, action) => {
//         let payload = {
//             additionalInfo: data['additionalInfo'],
//             conversionRatio: 0,
//             description: data['description'],
//             estimatedLifeValue: parseInt(data['estimatedLifeValue']),
//             goodsType: data['goodsType']['label'],
//             hsnCode: data['batch_number'],
//             isScrapGoods: data['is_scrap_goods']['label'].toLowerCase() === 'yes',
//             name: data['name'],
//             perUnitRate: parseInt(data['ratePerUnit']),
//             purchaseUnit: data['unit']['label'],
//             reOrderLevel: parseInt(data['reOrderLevel']),
//             remark: data['remark'],
//             salesUnit: data['unit']['label'],
//             stockType: data['stockType']['label'],
//             type: "GOODS",
//             categoryId:data['category']['id'],
//             categoryName :data['category']['label']
//         };

//         if (defaultRow.id !== undefined) {
//             payload['code'] = data['code'];
//             action = "patch";
//         }
//         else{
//           /*   payload['categoryId'] = data['categoryId'];
//             payload['categoryName'] = data['categoryName']; */
//         }

//         let response;
//         try {
//             if (action === "post") {
//                 response = await APIService.post(productInventoryApi.post, payload);
//             } else if (action === "patch") {
//                 response = await APIService.patch(`${productInventoryApi.patch}${defaultRow.id}`, JSON.stringify(payload));
//             }

//             if (response.status === 200 || response.status === 201) {
//                 toast.success("Inventory Product saved successfully");
//                 navigate(-1);
//             } else {
//                 throw new Error('Error saving data');
//             }
//         } catch (error) {
//             toast.error("Error while saving category!");
//         }
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         onAddInventoryProductSubmit(formValues, 'post');
//     };

//     return (
//         <form className="p-4 bg-white shadow-md rounded-lg">
//             {/* Title */}
//             <h2 className="text-2xl font-bold mb-6 text-center">Inventory Master Form</h2>

//             <div className="grid grid-cols-2 gap-4 mb-4">
//                 {defaultRow.id !== undefined && (
//                     <InputField
//                         name="code"
//                         label="Material Code"
//                         placeholder="Material Code"
//                         maxLength={12}
//                         required={true}
//                         disabled={true}
//                         value={formValues.code}
//                         onChange={(e) => handleInputChange("code", e.target.value)}
//                     />
//                 )}
//                 <InputField
//                     name="name"
//                     label="Material Short Name"
//                     placeholder="Material Short Name"
//                     required={true}
//                     maxLength={40}
//                     value={formValues.name}
//                     onChange={(e) => handleInputChange("name", e.target.value)}
//                 />
//                 {/* 
//                 <Dropdown
//                      options={categoryNameList}
//                      selectedOption={selectedOptions}
//                      label="Select Category"
//                      name="Category"
//                      required={true}
//                      onOptionSelect={(selectedOption) => 
//                             handleOptionSelectCategory(selectedOption)
//                         }
//                         /> */}
//                 <InputField
//                     name="description"
//                     label="Material Long Description"
//                     placeholder="Material Long Description"
//                     required={true}
//                     maxLength={250}
//                     value={formValues.description}
//                     onChange={(e) => handleInputChange("description", e.target.value)}
//                 />
//                 <Dropdown
//                     name="unit"
//                     label="Unit of Measurement (UoM)"
//                     selectedOption={formValues.unit}
//                     options={[
//                         { label: "KG", name: "KG" },
//                         { label: "CMT", name: "CMT" },
//                         { label: "LTR", name: "LTR" },
//                         { label: "NOS", name: "NOS" },
//                         { label: "SET", name: "SET" }
//                     ]}
//                     required={true}
//                     onOptionSelect={(selectedOption) => handleOptionSelect("unit", selectedOption)}
//                 />
//                 <Dropdown
//                     name="goodsType"
//                     label="Material Type"
//                     selectedOption={formValues.goodsType}
//                     options={[
//                         { label: "Raw Material", name: "raw_material" },
//                         { label: "Finished Goods", name: "finished_goods" },
//                         { label: "Spare Parts", name: "spare_parts" }
//                     ]}
//                     required={true}
//                     onOptionSelect={(selectedOption) => handleOptionSelect("goodsType", selectedOption)}
//                 />
//                 <InputField
//                     name="ratePerUnit"
//                     label="Unit Rate (INR)"
//                     placeholder="Price Per Unit"
//                     required={true}
//                     value={formValues.ratePerUnit}
//                     onChange={(e) => handleInputChange("ratePerUnit", e.target.value)}
//                 />
//                 <Dropdown
//                     name="stockType"
//                     label="Stock Type"
//                     selectedOption={formValues.stockType}
//                     options={[
//                         { label: "In Stock", name: "in_stock" },
//                         { label: "Out of Stock", name: "out_of_stock" },
//                         { label: "Pre-order", name: "pre_order" }
//                     ]}
//                     required={false}
//                     onOptionSelect={(selectedOption) => handleOptionSelect("stockType", selectedOption)}
//                 />
//                 <InputField
//                     name="estimatedLifeValue"
//                     label="Estimated Life Value"
//                     placeholder="Estimated Life Value"
//                     required={false}
//                     value={formValues.estimatedLifeValue}
//                     onChange={(e) => handleInputChange("estimatedLifeValue", e.target.value)}
//                 />
//                 <InputField
//                     name="reOrderLevel"
//                     label="Reorder Level"
//                     placeholder="Reorder Level"
//                     required={false}
//                     value={formValues.reOrderLevel}
//                     onChange={(e) => handleInputChange("reOrderLevel", e.target.value)}
//                 />
//                 <Dropdown
//                     name="is_scrap_goods"
//                     label="Is Scrap Goods"
//                     selectedOption={formValues.is_scrap_goods}
//                     options={[
//                         { id: 1, label: "Yes", name: "yes" },
//                         { id: 2, label: "No", name: "no" }
//                     ]}
//                     required={true}
//                     onOptionSelect={(selectedOption) => handleOptionSelect("is_scrap_goods", selectedOption)}
//                 />
//                 <InputField
//                     name="batch_number"
//                     label="HSN Code"
//                     placeholder="Batch Number"
//                     required={false}
//                     value={formValues.batch_number}
//                     onChange={(e) => handleInputChange("batch_number", e.target.value)}
//                 />
//                 <InputField
//                     name="remark"
//                     label="Remark"
//                     placeholder="Material Group"
//                     required={false}
//                     value={formValues.remark}
//                     onChange={(e) => handleInputChange("remark", e.target.value)}
//                 />
//             </div>

//             {/* Buttons */}
//             <div className='flex justify-center items-center space-x-4 mb-4'>
//                 <button type="button" onClick={() => navigate(-1)} className="bg-blue-500 text-white p-2 rounded">Back</button>
//                 <button type="button" onClick={()=> onAddInventoryProductSubmit(formValues,'post')} className="bg-blue-500 text-white p-2 rounded">Submit</button>
//             </div>
//         </form>
//     );
// }

// export default SupplyMaster;

// // import React from 'react';
// // import { useState } from 'react';
// // import { isEmpty } from "lodash";
// // import InputField from '../../components/InputField/InputField';
// // import Dropdown from '../../components/Dropdown/Dropdown';
// // //import { json, useNavigate } from "react-router-dom";
// // import {productInventoryApi} from "../../utils/API"
// // import { toast } from "react-toastify";
// // import { APIService } from "@/services";
// // import {  useNavigate,useLocation } from "react-router-dom";

// // import { 
// //   ADD_INVENTORY_PRODUCT_INITIAL_FORM_VALUE,
// // } from "./constants";
// // import { data } from 'autoprefixer';
// // import { Alert } from 'react-bootstrap';

// // function SupplyMaster() {
// //     const location = useLocation();


// //     const defaultRow = location.state != null ? location.state.selectedRow : ADD_INVENTORY_PRODUCT_INITIAL_FORM_VALUE;
// //    // console.log("data"+JSON.stringify(location.state.selectedRow));

// //     const [formValues, setFormValues] = useState({
// //         code: defaultRow.code,
// //         name: defaultRow.name,
// //         description: defaultRow.description,
// //         unit:  {label:defaultRow.purchaseUnit,name:defaultRow.purchaseUnit},
// //         goodsType: {label:defaultRow.goodsType,name:defaultRow.goodsType},
// //         ratePerUnit: defaultRow.perUnitRate,
// //         stockType: {label:defaultRow.stockType,name:defaultRow.stockType},
// //         estimatedLifeValue: defaultRow.estimatedLifeValue,
// //         reOrderLevel: defaultRow.reOrderLevel,
// //         is_scrap_goods: {label:defaultRow.isScrapGoods == "No" ? "no":"yes",} , // Is Scrap Goods dropdown
// //         remark: defaultRow.remark,
// //         batch_number: defaultRow.hsnCode,
// //     });

// //     const navigate = useNavigate();

// //     const handleInputChange = (name, value) => {
// //         setFormValues((prevValues) => ({
// //             ...prevValues,
// //             [name]: value,
// //         }));
// //     };
// //     const onAddInventoryProductSubmit = async (data, action) => {
// //        //alert(JSON.stringify(data));
// //         let payload = {
// //             additionalInfo: "",
// //   categoryId: "",
// //   categoryName: "",
            
// //             conversionRatio: 0,
// //             description: data['description'],
// //             estimatedLifeValue: parseInt(data['estimatedLifeValue']),
// //             goodsType: data['goodsType']['label'],
// //             hsnCode:data['batch_number'],
// //             isScrapGoods: data['is_scrap_goods']['label'].toLowerCase() == 'yes',
// //             name: data['name'],
// //             perUnitRate: parseInt(data['ratePerUnit']), 
// //             purchaseUnit: data['unit']['label'],
// //             reOrderLevel:parseInt(data['reOrderLevel']),
// //             remark: data['remark'],
// //             salesUnit: data['unit']['label'],
// //             stockType: data['stockType']['label'],
// //             type: "GOODS"
// //         }   
// //         if(defaultRow.id != undefined){
// //              payload['id'] = defaultRow.id;
// //              action = "patch";
// //         }       
// //        // console.log(JSON.stringify(payload));
// //         let response;
// //     //   data = JSON.stringify(payload);
// //         if (action === "post") {
// //             response = await APIService.post(productInventoryApi.post, payload);
// //         } else if (action === "patch") {
// //             alert(`${productInventoryApi.patch}${defaultRow.id}`);
// //             response = await APIService.patch(`${productInventoryApi.patch}${defaultRow.id}`, JSON.stringify(payload));
// //         }

// //         if (response.status === 200 || response.status === 201) {
// //             toast.success("Inventory Product saved successfully");
// //             navigate(-1);
// //         } else {
// //             toast.error("Error while saving category!");
// //             return;
// //         }

// //         /* setIsOpen(!isOpen);
// //         setCategoryListChanged(!categoryListChanged); */
// //     };

// //     const handleOptionSelect = (name, selectedOption) => {
// //         setFormValues((prevValues) => ({
// //             ...prevValues,
// //             [name]: selectedOption,
// //         }));
// //     };

// //     const handleSubmit = (e) => {
// //         e.preventDefault();
// //         console.log("Form Submitted: ", formValues);
// //     };

// //     return (
// //         <form  className="p-4 bg-white shadow-md rounded-lg">
// //             {/* First Row */}
// //             <div className="grid grid-cols-2 gap-4 mb-4">
// //               { defaultRow.id != undefined ?   <InputField 
// //                     name="code" 
// //                     label="Material Code" 
// //                     placeholder="Material Code" 
// //                     maxLength={12}
// //                     required={true} 
// //                     disabled={true}
// //                     value={formValues.code}
// //                     onChange={(e) => handleInputChange("code", e.target.value)}
// //                 />:null}

// //                 <InputField 
// //                     name="name" 
// //                     label="Material Short Name" 
// //                     placeholder="Material Short Name" 
// //                     required={true}
// //                     maxLength={40} 
// //                     value={formValues.name}
// //                     onChange={(e) => handleInputChange("name", e.target.value)}
// //                 />
              
// // {/*             </div> */}

// //             {/* Second Row */}
// //            {/*  <div className="grid grid-cols-2 gap-4 mb-4"> */}
// //                 <InputField 
// //                     name="description" 
// //                     label="Material Long Description" 
// //                     placeholder="Material Long Description" 
// //                     required={true} 
// //                     maxLength={250}                    
// //                     value={formValues.description}
// //                     onChange={(e) => handleInputChange("description", e.target.value)}
// //                 />

// //                 <Dropdown 
// //                     name="unit" 
// //                     label="Unit of Measurement (UoM)" 
// //                     id="uom" 
// //                     selectedOption={formValues.unit}
// //                     options={[
// //                         {  label: "KG", name: "KG" }, 
// //                         { label: "CMT", name: "CMT" }, 
// //                         { label: "LTR", name: "LTR" },
// //                         {  label: "NOS", name: "NOS" },
// //                         {  label: "SET", name: "SET" }
// //                     ]} 
// //                     required={true} 
// //                     alignment="text-left"
// //                     onOptionSelect={(selectedOption) => handleOptionSelect("unit", selectedOption)}
// //                 />
// //             {/* </div> */}

// //             {/* Third Row */}
// //            {/*  <div className="grid grid-cols-2 gap-4 mb-4"> */}
// //                 <Dropdown 
// //                     name="goodsType" 
// //                     label="Material Type" 
// //                     id="goods_type"
// //                     selectedOption={formValues.goodsType} 
// //                     options={[
// //                         {  label: "Raw Material", name: "raw_material" }, 
// //                         {  label: "Finished Goods", name: "finished_goods" }, 
// //                         {  label: "Spare Parts", name: "spare_parts" }
// //                     ]} 
// //                     required={true} 
// //                     alignment="text-left"
// //                     onOptionSelect={(selectedOption) => handleOptionSelect("goodsType", selectedOption)}
// //                 />

// //                 <InputField 
// //                     name="ratePerUnit" 
// //                     label="Unit Rate (INR)" 
// //                     placeholder="Price Per Unit" 
// //                     required={true} 
// //                     value={formValues.ratePerUnit}
// //                     onChange={(e) => handleInputChange("ratePerUnit", e.target.value)}
// //                 />
// //             {/* </div> */}

// //             {/* Fourth Row */}
// //            {/*  <div className="grid grid-cols-2 gap-4 mb-4"> */}
// //                 <Dropdown 
// //                     name="stock_type" 
// //                     label="Stock Type" 
// //                     id="stock_type" 
// //                     selectedOption={formValues.stockType}
// //                     options={[
// //                         { label: "In Stock", name: "in_stock" }, 
// //                         {  label: "Out of Stock", name: "out_of_stock" }, 
// //                         {  label: "Pre-order", name: "pre_order" }
// //                     ]} 
// //                     required={false} 
// //                     alignment="text-left"
// //                     onOptionSelect={(selectedOption) => handleOptionSelect("stockType", selectedOption)}
// //                 />

// //                 <InputField 
// //                     name="estimated_life_value" 
// //                     label="Estimated Life Value" 
// //                     placeholder="Estimated Life Value" 
// //                     required={false} 
// //                     value={formValues.estimatedLifeValue}
// //                     onChange={(e) => handleInputChange("estimatedLifeValue", e.target.value)}
// //                 />
// //             {/* </div> */}

// //             {/* Fifth Row */}
// //            {/*  <div className="grid grid-cols-2 gap-4 mb-4"> */}
// //                 <InputField 
// //                     name="reOrderLevel" 
// //                     label="Reorder Level" 
// //                     placeholder="Reorder Level" 
// //                     required={false} 
// //                     value={formValues.reOrderLevel}
// //                     onChange={(e) => handleInputChange("reOrderLevel", e.target.value)}
// //                 />

// //                 {/* Is Scrap Goods as Dropdown */}
// //                 <Dropdown 
// //                     name="is_scrap_goods" 
// //                     label="Is Scrap Goods" 
// //                     id="is_scrap_goods" 
// //                     selectedOption={formValues.is_scrap_goods}
// //                     options={[
// //                         { id: 1, label: "Yes", name: "yes" }, 
// //                         { id: 2, label: "No", name: "no" }
// //                     ]} 
// //                     required={true} 
// //                     alignment="text-left"
// //                     onOptionSelect={(selectedOption) => handleOptionSelect("is_scrap_goods", selectedOption)}
// //                 />
// //             {/* </div> */}

// //             {/* Sixth Row */}
// //             {/* <div className="grid grid-cols-2 gap-4 mb-4"> */}
// //                 <InputField 
// //                     name="batch_number" 
// //                     label="HSN Code" 
// //                     placeholder="Batch Number" 
// //                     required={false} 
// //                     value={formValues.batch_number}
// //                     onChange={(e) => handleInputChange("batch_number", e.target.value)}
// //                 />
// //                 <InputField 
// //                     name="remark" 
// //                     label="Remark" 
// //                     placeholder="Material Group" 
// //                     required={false} 
// //                     value={formValues.remark}
// //                     onChange={(e) => handleInputChange("remark", e.target.value)}
// //                 />
// //          </div> 

// //             {/* Buttons */}
// //             <div className='flex justify-center items-center space-x-4 mb-4'>
// //                 <button type="button" onClick={() => console.log(JSON.stringify(formValues))} className="bg-blue-500 text-white p-2 rounded">Back</button>
// //                 <button type="button" onClick={()=> onAddInventoryProductSubmit(formValues,'post')} className="bg-blue-500 text-white p-2 rounded">Submit</button>
// //             </div>
// //         </form>
// //     );
// // }

// // export default SupplyMaster;
