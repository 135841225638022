import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-tailwind/react";
import { Provider } from 'react-redux';
import App from './App.jsx'
import '../public/css/main.css';
import store from '../src/store/store';
import {
  MaterialTailwindProvider,
  AuthProvider,
  DataProvider,
  RolePermissionProvider
} from './context';

function Main() {
  return (
    // <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider>
          <MaterialTailwindProvider>
            <AuthProvider>
              <RolePermissionProvider>
                <DataProvider>
                  <App />
                </DataProvider>
              </RolePermissionProvider>
            </AuthProvider>
          </MaterialTailwindProvider>
        </ThemeProvider>
      </BrowserRouter>
      </Provider>
  );
}

ReactDOM.createRoot(document.getElementById('root')).render(<Main />);
