import React, { useState } from "react";
import Modal from "react-modal";
import Select from "react-select";
import { toast } from "react-toastify";
import { FaTrashAlt } from "react-icons/fa"; // Importing the trash icon from react-icons
import Button from "@/components/Button";

Modal.setAppElement("#root");

const AddMaterialIssue = ({ togglePopup, isOpen, onAddProductSubmit }) => {
  const [workOrderNo, setWorkOrderNo] = useState("");
  const [items, setItems] = useState([{ itemCode: null, quantity: "" }]);
  const [errorMessages, setErrorMessages] = useState({ workOrderNo: "", items: [] });

  const itemCodes = [
    { value: "ITEM001", label: "ITEM001" },
    { value: "ITEM002", label: "ITEM002" },
    { value: "ITEM003", label: "ITEM003" },
    { value: "ITEM004", label: "ITEM004" },
  ];

  const handleWorkOrderChange = (e) => {
    setWorkOrderNo(e.target.value);
  };

  const handleItemCodeChange = (index, selectedOption) => {
    const updatedItems = [...items];
    updatedItems[index].itemCode = selectedOption;
    setItems(updatedItems);
  };

  const handleQuantityChange = (index, value) => {
    const updatedItems = [...items];
    updatedItems[index].quantity = value;
    setItems(updatedItems);
  };

  const addRow = () => {
    setItems([...items, { itemCode: null, quantity: "" }]);
  };

  const removeRow = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };

  const handleSubmit = () => {
    const newErrors = { workOrderNo: "", items: [] };

    if (!workOrderNo.trim()) {
      newErrors.workOrderNo = "Work Order No. is required.";
    }

    newErrors.items = items.map((item) => {
      const error = {};
      if (!item.itemCode) error.itemCode = "Item Code is required.";
      if (!item.quantity || item.quantity <= 0) error.quantity = "Quantity must be greater than zero.";
      return error;
    });

    setErrorMessages(newErrors);

    const hasErrors =
      newErrors.workOrderNo ||
      newErrors.items.some((err) => Object.keys(err).length > 0);

    if (hasErrors) {
      toast.error("Please fix errors before submitting.");
      return;
    }

    const payload = {
      workOrderNo,
      items: items.map((item) => ({
        itemCode: item.itemCode.value,
        quantity: item.quantity,
      })),
    };

    onAddProductSubmit(payload, "post");
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={togglePopup}
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      className="bg-white p-6 rounded shadow-lg w-full max-w-2xl"
    >
      <h2 className="text-lg font-bold mb-4">Add Items to Work Order</h2>
      <form className="space-y-4">
        <div>
          <label className="block text-sm font-medium">Work Order No.</label>
          <input
            type="text"
            className="w-full p-2 border rounded"
            placeholder="Enter Work Order No."
            value={workOrderNo}
            onChange={handleWorkOrderChange}
          />
          {errorMessages.workOrderNo && (
            <span className="text-red-500 text-sm">{errorMessages.workOrderNo}</span>
          )}
        </div>

        <div className="space-y-4">
          <div className="grid grid-cols-12 gap-4 items-center border-b pb-2 mb-4">
            <div className="col-span-5 font-medium">Item Code</div>
            <div className="col-span-3 font-medium">Quantity</div>
            <div className="col-span-4 font-medium text-right">Actions</div>
          </div>
          {items.map((item, index) => (
            <div key={index} className="grid grid-cols-12 gap-4 items-center">
              <div className="col-span-5">
                <Select
                  options={itemCodes}
                  value={item.itemCode}
                  onChange={(selectedOption) => handleItemCodeChange(index, selectedOption)}
                  placeholder="Select Item Code"
                />
                {errorMessages.items[index]?.itemCode && (
                  <span className="text-red-500 text-sm">{errorMessages.items[index].itemCode}</span>
                )}
              </div>

              <div className="col-span-3">
                <input
                  type="number"
                  className="w-full p-2 border rounded"
                  value={item.quantity}
                  placeholder="Enter Quantity"
                  onChange={(e) => handleQuantityChange(index, e.target.value)}
                />
                {errorMessages.items[index]?.quantity && (
                  <span className="text-red-500 text-sm">{errorMessages.items[index].quantity}</span>
                )}
              </div>

              <div className="col-span-4 text-right">
                <button
                  type="button"
                  className="text-red-500 hover:text-red-700 p-2"
                  onClick={() => removeRow(index)}
                  title="Remove Item"
                >
                  <FaTrashAlt size={18} />
                </button>
              </div>
            </div>
          ))}
          <button
            type="button"
            className="mt-4 bg-green-500 text-white px-4 py-2 rounded"
            onClick={addRow}
          >
            Add Row
          </button>
        </div>

        <Button text="Submit" textColor="white" bgColor="blue-500" onClick={handleSubmit} />
      </form>
    </Modal>
  );
};

export default AddMaterialIssue;



// import React, { useEffect, useState } from "react";
// import Modal from "react-modal";
// import Select from "react-select";
// import { toast } from "react-toastify";
// import { set, cloneDeep, isEmpty, get, pull, concat } from "lodash";

// import InputField from "@/components/InputField"
// import Button from "@/components/Button";
// import { 
//     PRODUCT_INITIAL_FORM_VALUE,
//     PRODUCT_ERROR_MESSAGE_VALUES,
//     PRODUCT_REQUIRED_FIELDS
// } from "./constants";
// import { REGEX } from "@/utils/AppConstants";
// import { Textarea } from "@material-tailwind/react";
// Modal.setAppElement("#root");

// const AddMaterialIssue = ({ togglePopup, isOpen, uomList, onAddProductSubmit, selectedRow }) => {
//     const [product, setProduct] = useState(!isEmpty(selectedRow) ? selectedRow : PRODUCT_INITIAL_FORM_VALUE);
//     const [errorMessages, setErrorMessages] = useState(PRODUCT_ERROR_MESSAGE_VALUES);
//     const [selectedOptions, setSelectedOptions] = useState(!isEmpty(selectedRow) ? { value: selectedRow.categoryId, label: selectedRow.categoryName } : []);
//     let PRODUCT_FORM_REQUIRED_FIELDS = PRODUCT_REQUIRED_FIELDS;
//     const itemCodes = [
//         { value: 'ITEM001', label: 'ITEM001' },
//         { value: 'ITEM002', label: 'ITEM002' },
//         { value: 'ITEM003', label: 'ITEM003' },
//         { value: 'ITEM004', label: 'ITEM004' }
//       ];
    
//     // define the function to handle option selection
//     const handleOptionSelect = (selectedOptions) => {
//         setSelectedOptions(selectedOptions);
//     };

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         const updatedState = cloneDeep(product);
//         set(updatedState, name, value);
//         setProduct(updatedState);
//     };

//     const makeDependentFieldsMandatory = () => {
//         if (!isEmpty(selectedOptions)) {
//             if (selectedOptions.label === "Trailer Type" && selectedOptions.value === "01") {
//                 PRODUCT_FORM_REQUIRED_FIELDS = concat(PRODUCT_FORM_REQUIRED_FIELDS, "code");
//             } else {
//                 pull(PRODUCT_FORM_REQUIRED_FIELDS, "code");
//             }
//         };
//     };

//     const handleSubmit = async () => {
//         makeDependentFieldsMandatory();

//         const newErrorMessages = {};
//         PRODUCT_FORM_REQUIRED_FIELDS.forEach((field) => {
//             if (!get(product, field)) {
//                 newErrorMessages[field] = "This field is required.";
//             } else {
//                 newErrorMessages[field] = "";
//                 if (field === "originalPrice" || field === "stock") {
//                     if (!get(product, field).toString().match(REGEX.WHOLE_NUMBER)) {
//                         newErrorMessages[field] = "Please enter valid input";
//                     }
//                 };
//             }
//         });

//         setErrorMessages(newErrorMessages);
//         if (Object.values(newErrorMessages).some((errorMsg) => errorMsg !== "")) {
//             toast.error("Please fill mandatory fields");
//             return;
//         }

//         const payLoad = {
//             name: product?.name,
//             description: product?.description,
//             originalPrice: product?.originalPrice,
//             stock: product?.stock,
//             code: product?.code
//         };

//         if (!isEmpty(selectedRow)) {
//             const payload = {
//                 name: product?.name,
//                 description: product?.description,
//                 categoryId: selectedOptions.value,
//                 originalPrice: 1.0,
//                 stock: product?.stock,
//                 code: product?.code
//             };

//             //const data = selectedOptions ? { ...payload, productCode: product?.name } : payload;
//             const data = { ...payload };
//             onAddProductSubmit(data, "patch");
//         } else {
//             const data = selectedOptions ? { ...payLoad, categoryId: selectedOptions.value } : payLoad;
//             onAddProductSubmit(data, "post");
//         }
//     };

//     useEffect(() => {
//         makeDependentFieldsMandatory();
//     }, []);

//     return (
//         <div>
//             <Modal
//                 isOpen={isOpen}
//                 onRequestClose={togglePopup}
//                 contentLabel="Example Modal"
//                 overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
//                 className="bg-white p-6 rounded shadow-lg"
//             >
//                         <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
//           <div className="bg-white p-6 rounded-lg w-96">
//             <h2 className="text-lg font-bold mb-4">Issue to Work Order</h2>
//             <form className="space-y-4">
//               <div>
//                 <label className="block text-sm font-medium">Work Order No.</label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border rounded"
//                   placeholder="Enter Work Order No."
//                 />
//               </div>
//               <div>
//                 <label className="block text-sm font-medium">Item Code</label>
//                 <Select options={itemCodes} placeholder="Select Item Code" />
//               </div>
//               <div>
//                 <label className="block text-sm font-medium">Description</label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border rounded"
//                   placeholder="Auto-populated" 
//                   disabled
//                 />
//               </div>
//               <div>
//                 <label className="block text-sm font-medium">Unit</label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border rounded"
//                   placeholder="Enter Unit"
//                 />
//               </div>
//               <div>
//                 <label className="block text-sm font-medium">Quantity</label>
//                 <input
//                   type="number"
//                   className="w-full p-2 border rounded"
//                   placeholder="Enter Quantity"
//                 />
//               </div>
//               <button
//                 type="button"
//                 className="w-full mt-4 bg-red-500 text-white p-2 rounded"
//                 onClick={() => setShowWorkOrderModal(false)}
//               >
//                 Issue
//               </button>
//             </form>
//           </div>
//         </div>

    
    
               
       
//             </Modal>
//         </div>
        

//     );
// };

// export default AddMaterialIssue;