import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import { set, cloneDeep, get, isEmpty } from "lodash";
import { toast } from "react-toastify";
import moment from "moment/moment";

import Dropdown from "@/components/Dropdown";
import Button from "@/components/Button";
import InputField from "@/components/InputField"
import TextAreaField from "@/components/TextAreaField";
import ErrorMessage from "@/components/ErrorMessage"
import MyDatePicker from "@/components/DatePickerComponent";
import { useData } from "@/hooks/useData";

import { REGEX } from "@/utils/AppConstants";
import { useAuth } from "@/hooks/useAuth";
import { DAILY_STATUS_FORM_VALUE, DAILY_STATUS_REQUIRED_FIELDS, DAILY_STATUS_ERROR_MESSAGE_VALUES } from "../constant";

const AddWorkOrderProductionStatusPopup = ({ togglePopup, isOpen, order, statusList, onAddDailyStatusSubmit, selectedRow }) => { 
    const [queryParams] = useSearchParams();
    const navigate = useNavigate();
    const {data} =  useData();
    const { user } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const [formValues, setFormValues] = useState(!isEmpty(selectedRow) ? selectedRow : DAILY_STATUS_FORM_VALUE);
    const [errorMessages, setErrorMessages] = useState(DAILY_STATUS_ERROR_MESSAGE_VALUES);
    const [subStatusList, setSubStatusList] = useState([]);
    const statusLabel = !isEmpty(selectedRow) ? "Update" : "Add";

    // get total number of persons
    const getTotalPersons = () => {
        const { skilled, semiSkilled, unSkilled, totalTimeTaken } = formValues;
        const totalPerson =  parseInt(skilled.numberOfPerson) + parseInt(semiSkilled.numberOfPerson) + parseInt(unSkilled.numberOfPerson);
        return !isNaN(totalPerson) ? Number(totalPerson): "";
    };

    // get grand total hours for all person
    const getGrandTotalManHours = () => {
        const { skilled, semiSkilled, unSkilled } = formValues;
        const total = Number(skilled.totalManHourTime) + Number(semiSkilled.totalManHourTime) + Number(unSkilled.totalManHourTime);
        return !isNaN(total) ? Number(total): "";
    };

    const getTotalTimeTaken = () => {
        if (formValues.processStartTime && formValues.processEndTime) {  
            const endDate = moment(formValues.processEndTime);
            const startDate = moment(formValues.processStartTime);
            const totalTime = moment.duration(endDate - startDate).as("hours");
            return totalTime;
        }
        return 0;
    };

    const getCurretDate = () => {
        const formattedDateString = moment().format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)");
        return new Date(formattedDateString);
    };

    const handleDateChange = (name, date) => {
        updateFormState(name, date);
    };

    const handleOptionSelect = (option, id) => {
        const updatedState = cloneDeep(formValues);
        set(updatedState, id, option.name);
        // console.log(updatedState);
        setFormValues(updatedState);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        updateFormState(name, value);
    };

    const updateFormState = (name, value) => {
        const updatedState = cloneDeep(formValues);
     
        const organization = data.organization;
       
        set(updatedState, name, value);

        if (name === "processStartTime") {
            const startDate = moment(value);
            const endDate = moment(formValues.processEndTime);
            const totalTimeTaken = moment.duration(endDate - startDate).as("hours");

            const { skilled, semiSkilled, unSkilled } = formValues;
            const skilledManHours = Number(totalTimeTaken * parseInt(skilled.numberOfPerson));
            const semiskilledManHours = Number(totalTimeTaken * parseInt(semiSkilled.numberOfPerson));
            const unskilledManHours = Number(totalTimeTaken * parseInt(unSkilled.numberOfPerson));
           
            set(updatedState, "totalTimeTaken", totalTimeTaken);
            set(updatedState, "skilled.totalManHourTime", !isNaN(skilledManHours) ? skilledManHours: 0);
            set(updatedState, "semiSkilled.totalManHourTime", !isNaN(semiskilledManHours) ? semiskilledManHours: 0);
            set(updatedState, "unSkilled.totalManHourTime", !isNaN(unskilledManHours) ? unskilledManHours: 0);
      
        };

        if (name === "processEndTime") {
            const startDate = moment(formValues.processStartTime);
            const endDate = moment(value);
            const totalTimeTaken = moment.duration(endDate - startDate).as("hours");
            
            const { skilled, semiSkilled, unSkilled } = formValues;
            const skilledManHours = Number(totalTimeTaken * parseInt(skilled.numberOfPerson));
            const semiskilledManHours = Number(totalTimeTaken * parseInt(semiSkilled.numberOfPerson));
            const unskilledManHours = Number(totalTimeTaken * parseInt(unSkilled.numberOfPerson));
            
            set(updatedState, "totalTimeTaken", totalTimeTaken);
            set(updatedState, "skilled.totalManHourTime", !isNaN(skilledManHours) ? skilledManHours: 0);
            set(updatedState, "semiSkilled.totalManHourTime", !isNaN(semiskilledManHours) ? semiskilledManHours: 0);
            set(updatedState, "unSkilled.totalManHourTime", !isNaN(unskilledManHours) ? unskilledManHours: 0);
        };

        if (name === "skilled.numberOfPerson") {
            const totalTimeTaken = getTotalTimeTaken();
            const skilledManHours = Number(totalTimeTaken * parseInt(value));
            set(updatedState, "skilled.totalManHourTime", !isNaN(skilledManHours) ? skilledManHours: 0);
        };

        if (name === "semiSkilled.numberOfPerson") {
            const totalTimeTaken = getTotalTimeTaken();
            const semiskilledManHours = Number(totalTimeTaken * parseInt(value));
            set(updatedState, "semiSkilled.totalManHourTime", !isNaN(semiskilledManHours) ? semiskilledManHours: 0);
        };

        if (name === "unSkilled.numberOfPerson") {
            const totalTimeTaken = getTotalTimeTaken();
            const unskilledManHours = Number(totalTimeTaken * parseInt(value));
            set(updatedState, "unSkilled.totalManHourTime", !isNaN(unskilledManHours) ? unskilledManHours: 0);
        };
        if (organization.organizationList && !isEmpty(organization.organizationList)) {
            let totalManCost = 0;
            let organizationWages = organization.organizationList[0].workerWages;
            const { skilled, semiSkilled, unSkilled } = updatedState;

           if(skilled.numberOfPerson !== "" && semiSkilled.numberOfPerson !== "" && unSkilled.numberOfPerson !== "") {
            //totalManCost = ((parseInt(skilled.totalManHourTime) *parseInt(skilled.numberOfPerson)) * organizationWages.skilled) + (parseInt(semiSkilled.numberOfPerson) * organizationWages.semiSkilled) + (parseInt(unSkilled.numberOfPerson) * organizationWages.unSkilled);
            totalManCost = (parseInt(skilled.totalManHourTime) * parseInt(skilled.numberOfPerson) * organizationWages.skilled) + (parseInt(semiSkilled.totalManHourTime) * organizationWages.semiSkilled * parseInt(semiSkilled.numberOfPerson)) + (parseInt(unSkilled.numberOfPerson) * parseInt(unSkilled.totalManHourTime) * organizationWages.unSkilled);
            set(updatedState, "remark", totalManCost.toString()); 
           }
        }
        // console.log(updatedState);
        setFormValues(updatedState);
    };

    const selectedOption = (value, options, field) => {
        const selectedValue = options.find((option) => {
            return option[field] === value
        });
        return selectedValue;
    };

    const setWorkOrderDetails = async () => {
        setIsLoading(true);
        if (!isEmpty(order) && !isEmpty(statusList)) {
            patchForm(order);
            setWorkOrderStatusList(order.status, statusList);
        }
        setIsLoading(false);
    };

    const patchForm = (order) => {
        const formattedDateString = moment().format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)");
        const updatedState = cloneDeep(formValues);
        set(updatedState, "status", order.status);
        set(updatedState, "workOrderId", order.orderId);
        set(updatedState, "createdDate", new Date(formattedDateString));
        set(updatedState, "productionPersonalName", user.username);
        set(updatedState, "processStartTime", formValues.processStartTime ? new Date(formValues.processStartTime) : new Date());
        set(updatedState, "processEndTime", formValues.processEndTime ? new Date(formValues.processEndTime) : new Date());
        // console.log(updatedState);
        setFormValues(updatedState);
    };

    const setWorkOrderStatusList = (orderStatus, productionStatusList) => {
        const selectedStatus = productionStatusList.find(item => {
            return item.statusName.toLowerCase() === orderStatus.toLowerCase()
        });

        if (selectedStatus) {
            const subStatusList = selectedStatus.internalStatus?.map((item, i) => {
                return { label: item, value: i, name: item }
            });
            setSubStatusList(subStatusList);
        }
    };

    const handleSubmit = async () => {
        setIsPending(true);
        let newErrorMessages = {};
        DAILY_STATUS_REQUIRED_FIELDS.forEach((field) => {
            if ( isEmpty(get(formValues, field))) {
                if( get(formValues, field) != 0){
                newErrorMessages[field] = "This field is required.";
                }
            } else {
                newErrorMessages[field] = "";
                let totalPerson = getTotalPersons();
                if (field === "skilled.numberOfPerson") {
                    if (!get(formValues, field).toString().match(REGEX.POSITIVE_GRT_ZERO) && totalPerson == 0) {
                        newErrorMessages[field] = "Please enter valid input";
                    }
                }

                if (field === "semiSkilled.numberOfPerson" && totalPerson == 0) {
                    if (!get(formValues, field).toString().match(REGEX.POSITIVE_GRT_ZERO)) {
                        newErrorMessages[field] = "Please enter valid input";
                    }
                }

                if (field === "unSkilled.numberOfPerson" && totalPerson == 0) {
                    if (!get(formValues, field).toString().match(REGEX.POSITIVE_GRT_ZERO)) {
                        newErrorMessages[field] = "Please enter valid input";
                    }
                }
            }
        });
        setErrorMessages(newErrorMessages);
        if (Object.values(newErrorMessages).some((errorMsg) => errorMsg !== "")) {
            setIsPending(false);
            toast.error("Please fill mandatory fields");
            return;
        };

        const payload = cloneDeep(formValues);
        const dateFormat = "YYYY-MM-DD HH:mm:ss +0530";

        set(payload, "totalManHourTime", getGrandTotalManHours());
        set(payload, "totalNumberOfPerson", getTotalPersons());
        set(payload, "skilled.numberOfPerson", parseInt(payload.skilled.numberOfPerson));
        set(payload, "semiSkilled.numberOfPerson", parseInt(payload.semiSkilled.numberOfPerson));
        set(payload, "unSkilled.numberOfPerson", parseInt(payload.unSkilled.numberOfPerson));
        set(payload, "processEndTime", moment(payload.processEndTime).format(dateFormat));
        set(payload, "processStartTime", moment(payload.processStartTime).format(dateFormat));
        set(payload, "createdDate", moment(payload.createdDate).format(dateFormat));
        // console.log(payload);
        onAddDailyStatusSubmit(payload);
    };

    useEffect(() => {
       // const data = useData();
        setWorkOrderDetails();
    }, []);


    return (
        <div>
            <Modal
                isOpen={isOpen}
                onRequestClose={togglePopup}
                contentLabel="Example Modal"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                className="bg-white p-5 rounded shadow-lg w-10/12"
            >
                <div className="">
                    <span className="text-xl font-small pt-3.5">{statusLabel} Daily Status</span>
                    <div className="grid grid-cols-4 gap-2 pt-3.5">
                        <div>
                            <InputField
                                name="status"
                                label="Order Status"
                                value={get(formValues, "status")}
                                onChange={(e) => handleInputChange(e)}
                                required={true}
                                disabled={true}
                            />
                            {get(errorMessages, "status") && <ErrorMessage error={get(errorMessages, "status")} />}
                        </div>
                        <div>
                            <MyDatePicker 
                                label="Date"
                                name="createdDate"
                                selectedDate={getCurretDate()}
                                required={true}
                                disabled={true}
                            />
                            {get(errorMessages, "createdDate") && <ErrorMessage error={get(errorMessages, "createdDate")} />}
                        </div>
                        <div>
                            <InputField
                                name="productionPersonalName"
                                label="Person Name"
                                value={get(formValues, "productionPersonalName")}
                                onChange={(e) => handleInputChange(e)}
                                required={true}
                                disabled={true}
                            />
                            {get(errorMessages, "productionPersonalName") && <ErrorMessage error={get(errorMessages, "productionPersonalName")} />}
                        </div>
                        <div>
                            <Dropdown
                                options={subStatusList ?? []}
                                selectedOption={selectedOption(get(formValues, "subProcess"), subStatusList, "label")}
                                onOptionSelect={(option, id) => handleOptionSelect(option, id)}
                                label="Work Status"
                                name="subProcess"
                                id="subProcess"
                                required={true}
                            />
                            {get(errorMessages, "subProcess") && <ErrorMessage error={get(errorMessages, "subProcess")} />}
                        </div>
                        <div>
                            <MyDatePicker 
                                label="Process Start Time"
                                name="processStartTime"
                                selectedDate={get(formValues, "processStartTime")}
                                handleDateChange={handleDateChange}
                                required={true}
                                showTimeSelect={true}
                            />
                        </div>
                        <div>
                            <MyDatePicker 
                                label="Process End Time"
                                name="processEndTime"
                                selectedDate={get(formValues, "processEndTime")}
                                handleDateChange={handleDateChange}
                                required={true}
                                showTimeSelect={true}
                            />
                        </div>
                        <div>
                            <InputField
                                name="totalTimeTaken"
                                label="Total Time Taken (In Hours)"
                                value={getTotalTimeTaken()}
                                onChange={handleInputChange}
                                required={true}
                                disabled={true}
                            />
                        </div>
                        <div>
                            <InputField
                                name="totalNumberOfPerson"
                                label="Total Persons"
                                value={getTotalPersons()}
                                onChange={(e) => handleInputChange(e)}
                                required={true}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-4 gap-4 pt-3.5">
                        <div>
                            <InputField
                                name="skilled.numberOfPerson"
                                label="Skilled Persons"
                                placeholder="Enter total skill persons"
                                value={get(formValues, "skilled.numberOfPerson")}
                                onChange={(e) => handleInputChange(e)}
                                required={true}
                            />
                            {get(errorMessages, "skilled.numberOfPerson") && <ErrorMessage error={get(errorMessages, "skilled.numberOfPerson")} />}
                        </div>
                        <div>
                            <InputField
                                name="skilled.totalManHourTime"
                                label="Skilled Man Hours"
                                value={get(formValues, "skilled.totalManHourTime")}
                                onChange={(e) => handleInputChange(e)}
                                required={true}
                                disabled={true}
                            />
                        </div>
                        <div>
                            <InputField
                                name="semiSkilled.numberOfPerson"
                                label="Semi-Skilled Persons"
                                placeholder="Enter total semi-skill persons"
                                value={get(formValues, "semiSkilled.numberOfPerson")}
                                onChange={(e) => handleInputChange(e)}
                                required={true}
                            />
                            {get(errorMessages, "semiSkilled.numberOfPerson") && <ErrorMessage error={get(errorMessages, "semiSkilled.numberOfPerson")} />}
                        </div>
                        <div>
                            <InputField
                                name="semiSkilled.totalManHourTime"
                                label="Semi-Skilled Man Hours"
                                value={get(formValues, "semiSkilled.totalManHourTime")}
                                onChange={(e) => handleInputChange(e)}
                                required={true}
                                disabled={true}
                            />
                        </div>
                        <div>
                            <InputField
                                name="unSkilled.numberOfPerson"
                                label="Un-Skilled Persons"
                                value={get(formValues, "unSkilled.numberOfPerson")}
                                onChange={(e) => handleInputChange(e)}
                                required={true}
                            />
                            {get(errorMessages, "unSkilled.numberOfPerson") && <ErrorMessage error={get(errorMessages, "unSkilled.numberOfPerson")} />}
                        </div>
                        <div>
                            <InputField
                                name="unSkilled.totalManHourTime"
                                label="Un-Skilled Man Hours"
                                placeholder="Enter total un-skill persons"
                                value={get(formValues, "unSkilled.totalManHourTime")}
                                onChange={(e) => handleInputChange(e)}
                                required={true}
                                disabled={true}
                            />
                        </div>
                        <div>
                            <InputField
                                name="totalManHourTime"
                                label="Total Man Hours"
                                value={getGrandTotalManHours()}
                                onChange={handleInputChange}
                                required={true}
                                disabled={true}
                            />
                        </div>
                        <div>
                            <InputField
                                name="remark"
                                label="Total Man Cost"
                                placeholder="Enter total man cost"
                                value={get(formValues, "remark")}
                                onChange={handleInputChange}
                                required={true}
                            />
                            {get(errorMessages, "remark") && <ErrorMessage error={get(errorMessages, "remark")} />}
                        </div>
                        <div>
                            <InputField
                                name="comments"
                                label="Comment"
                                placeholder="Enter comment"
                                value={get(formValues, "comments")}
                                onChange={handleInputChange}
                                required={true}
                            />
                            {get(errorMessages, "comments") && <ErrorMessage error={get(errorMessages, "comments")} />}
                        </div>
                        <div className="col-span-1">
                            <TextAreaField
                                name="additionalInfo"
                                label="Additional Information"
                                placeholder="Enter your description here"
                                value={get(formValues, "additionalInfo")}
                                onChange={handleInputChange}
                                required={false}
                            />
                        </div>
                    </div>
                    {!isPending &&
                        <div className="mt-4 flex gap-2">
                            <Button text={`${statusLabel} Status`} textColor="white" bgColor="[#39ACE4]" onClick={() => handleSubmit()} />
                            <Button text="Cancel" textColor="Black" bgColor="White" onClick={() => togglePopup()} />
                        </div>
                    }
                    {isPending && <ColorRing
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="color-ring-loading"
                        wrapperStyle={{}}
                        wrapperClass="color-ring-wrapper"
                        colors={["#39ACE4","#39ACE4","#39ACE4","#39ACE4","#39ACE4" ]}
                    />}
                </div>
            </Modal>
        </div>
    );
}

export default AddWorkOrderProductionStatusPopup;