export const createRequestPayload = (formValues, payloadName) => {
  console.log(formValues);
  let payload = {};
  const totalCostOfTrailer =
    Number(formValues.perTrailerCost) * Number(formValues.quantity);

  if (payloadName === "quotation") {
    payload = {
      ...formValues,
      basicCost: Number(formValues.basicCost) || 0,
      quantity: Number(formValues.quantity) || 0,
      gstPercentage: formValues.gstPercentage
        ? formValues?.gstPercentage.toString()
        : "",
      gstAmount: formValues.gstAmount.toString(),
      perTrailerCost: Number(formValues.perTrailerCost) || 0,
      totalCostOfTrailer: totalCostOfTrailer || 0,
      axlesMake: {
        make: [].concat(formValues.axlesMake),
        type: [].concat(formValues.axlesType),
      },
      suspension: {
        combination: [].concat(formValues.suspensionCombination),
        make: [].concat(formValues.suspensionMake),
        type: [].concat(formValues.suspensionType),
      },
      // tyre: {
      //   make: [].concat(formValues.tyre),
      //   nylon: formValues?.radialNylon === "Nylon",
      //   radial: formValues?.radialNylon === "Radial",
      // },
      tyre: {
        make: [].concat(formValues.tyreMake),
        type: [].concat(formValues.tyreType),
      },
      length: formValues?.trailerLength || "",
      width: formValues?.trailerWidth?.productName || "",
      height: formValues?.trailerHeight?.productName || "",
      axleType: formValues?.trailerAxleType?.productName || "",
      sideWallType: null,
      customerAddress: {
        ...formValues.customerAddress,
        country: formValues.note, // reusing for delivery note
      },
      deliveryTerms:formValues?.deliveryTerms || "",
      advanceTerms:formValues?.advanceTerms  || ""
      //length: formValues?.trailerLength?.productName || "",
    };
  } else {
    payload = {
      costOfTrailer: Number(formValues.perTrailerCost) || 0,
      quantity: Number(formValues.quantity),
      totalCostOfTrailer: totalCostOfTrailer || 0,
      customerName: formValues.customerName,
      customerAddress: {
        ...formValues.customerAddress,
        country: formValues.note, // reusing for delivery note
      },
      contactMedium: {
        email: formValues.contactMedium.email,
        phoneNumber: formValues.contactMedium.phoneNumber,
      },
      inquiryAttended: formValues.inquiryAttended,
      inquiryAttendedDate: formValues.inquiryAttendedDate,
      trailerType: formValues.trailerType,
      sideWallType: null,
      trailerDescription: formValues.trailerDescription,
      application: formValues.application,
      truckModel: formValues.truckModel,
      axlesMake: {
        make: [].concat(formValues.axlesMake),
        type: [].concat(formValues.axlesType),
      },
      suspension: {
        combination: [].concat(formValues.suspensionCombination),
        make: [].concat(formValues.suspensionMake),
        type: [].concat(formValues.suspensionType),
      },
      // tyre: {
      //   make: [].concat(formValues.tyre),
      //   nylon: formValues?.radialNylon === "Nylon",
      //   radial: formValues?.radialNylon === "Radial",
      // },
      tyre: {
        make: [].concat(formValues.tyreMake),
        type: [].concat(formValues.tyreType),
      },
      landingLag: formValues.landingLag,
      brakeKit: formValues.brakeKit,
      brakeChamber: formValues.brakeChamber,
      floorSheet: formValues.floorSheet,
      coilLashing: formValues.coilLashing,
      wheelRim: formValues.wheelRim,
      kingpin: formValues.kingpin,
      electrical: formValues.electrical,
      twistLock: formValues.twistLock,
      tieDownHook: formValues.tieDownHook,
      khutlaPocket: formValues.khutlaPocket,
      khutlaPillarDetails: formValues.khutlaPillarDetails,
      supd: formValues.supd,
      spareWheelCarrierBkt: formValues.spareWheelCarrierBkt,
      additionalInformation: formValues.additionalInformation,
      mainSpineIBeam: formValues.mainSpineIBeam,
      actuators: formValues.actuators,
      paddingPlate: formValues.paddingPlate,
      toolBox: formValues.toolBox,
      crossMember: formValues.crossMember,
      fishPlate: formValues.fishPlate,
      sideRave: formValues.sideRave,
      taperSupport: formValues.taperSupport,
      reflectiveTape: formValues.reflectiveTape,
      colourShade: formValues.colourShade,
      fabrication: formValues.fabrication,
      lashingPate: formValues.lashingPate,
      surfaceTreatment: formValues.surfaceTreatment,
      tailDoorWindow: formValues.tailDoorWindow,
      quotationId: formValues.id,
      length: formValues?.trailerLength || "",
      width: formValues?.trailerWidth?.productName || "",
      height: formValues?.trailerHeight?.productName || "",
      axleType: formValues?.trailerAxleType?.productName || "",
      deliveryTerms:formValues?.deliveryTerms || "",
      advanceTerms:formValues?.advanceTerms  || ""
      //length: formValues?.trailerLength?.productName || "",
    };
  }

  delete payload["trailerLength"];
  delete payload["trailerWidth"];
  delete payload["trailerAxleType"];
  delete payload["trailerHeight"];
  // delete payload["axlesMake"];
  // delete payload["axlesType"];
  delete payload["suspensionMake"];
  delete payload["suspensionType"];
  delete payload["tyreMake"];
  delete payload["tyreType"];
  delete payload["suspensionCombination"];
  // delete payload["radialNylon"];
  return payload;
};

export const updateWorkOrderPayload = (formValues) => {
  let payload = {};
  const totalCostOfTrailer =
    Number(formValues?.perTrailerCost) * Number(formValues?.quantity);

  payload = {
    costOfTrailer: formValues?.perTrailerCost.toString() || "0",
    quantity: formValues?.quantity.toString() || "0",
    totalCostOfTrailer: totalCostOfTrailer.toString() || "0",
    customerName: formValues.customerName,
    customerAddress: {
      ...formValues.customerAddress,
      country: formValues.note, // reusing for delivery note
    },
    contactMedium: {
      email: formValues.contactMedium.email,
      phoneNumber: formValues.contactMedium.phoneNumber,
    },
    inquiryAttended: formValues.inquiryAttended,
    inquiryAttendedDate: formValues.inquiryAttendedDate,
    trailerType: formValues.trailerType,
    //length: formValues?.trailerLength?.productName || "",
    length: formValues?.trailerLength || "",
    width: formValues?.trailerWidth?.productName || "",
    axleType: formValues?.trailerAxleType?.productName || "",
    height: formValues?.trailerHeight?.productName || "",
    sideWallType: null,
    trailerDescription: formValues.trailerDescription,
    application: formValues.application,
    truckModel: formValues.truckModel,
    axlesMake: {
      make: [].concat(formValues.axlesMake),
      type: [].concat(formValues.axlesType),
    },
    suspension: {
      combination: [].concat(formValues.suspensionCombination),
      make: [].concat(formValues.suspensionMake),
      type: [].concat(formValues.suspensionType),
    },
    // tyre: {
    //   make: [].concat(formValues.tyre),
    //   nylon: formValues?.radialNylon === "Nylon",
    //   radial: formValues?.radialNylon === "Radial",
    // },
    tyre: {
      make: [].concat(formValues.tyreMake),
      type: [].concat(formValues.tyreType),
    },
    landingLag: formValues.landingLag,
    brakeKit: formValues.brakeKit,
    brakeChamber: formValues.brakeChamber,
    floorSheet: formValues.floorSheet,
    coilLashing: formValues.coilLashing,
    wheelRim: formValues.wheelRim,
    kingpin: formValues.kingpin,
    electrical: formValues.electrical,
    twistLock: formValues.twistLock,
    tieDownHook: formValues.tieDownHook,
    khutlaPocket: formValues.khutlaPocket,
    khutlaPillarDetails: formValues.khutlaPillarDetails,
    supd: formValues.supd,
    spareWheelCarrierBkt: formValues.spareWheelCarrierBkt,
    additionalInformation: formValues.additionalInformation,
    mainSpineIBeam: formValues.mainSpineIBeam,
    actuators: formValues.actuators,
    paddingPlate: formValues.paddingPlate,
    toolBox: formValues.toolBox,
    crossMember: formValues.crossMember,
    fishPlate: formValues.fishPlate,
    sideRave: formValues.sideRave,
    taperSupport: formValues.taperSupport,
    reflectiveTape: formValues.reflectiveTape,
    colourShade: formValues.colourShade,
    fabrication: formValues.fabrication,
    lashingPate: formValues.lashingPate,
    surfaceTreatment: formValues.surfaceTreatment,
    tailDoorWindow: formValues.tailDoorWindow,
    quotationId: formValues.quotationId,
  };
  console.log("payload" + payload);
  return payload;
};
