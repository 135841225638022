import React, { useState, useEffect } from "react";
import Select from "react-select";
import Modal from "react-modal";
import { set, cloneDeep, get, isEmpty, isBoolean } from "lodash";

import Dropdown from "@/components/Dropdown";
import Checkbox from "@/components/Checkbox";
import Button from "@/components/Button";
import {
  MODULES_LIST,
  INITIAL_PERMISSION_FORM_VALUE,
  PERMISSION_ERROR_MESSAGE_VALUES,
  PERMISSION_REQUIRED_FIELDS,
} from "./Constant";
import { REGEX } from "@/utils/AppConstants";

Modal.setAppElement("#root");

const AddPermissionPopup = ({
  togglePermissionPopup,
  isOpen,
  onAddPermssionSubmit,
  selectedRow,
  roleData,
}) => {
  const [addPermssionData, setAddPermssionData] = useState(
    !isEmpty(selectedRow) ? selectedRow : INITIAL_PERMISSION_FORM_VALUE
  );
  const [errorMessages, setErrorMessages] = useState(
    PERMISSION_ERROR_MESSAGE_VALUES
  );
  const [selectedRoles, setSelectedRoles] = useState(null);
  const roleList = roleData?.map((item) => {
    return { label: item?.name, value: item?.id };
  });
  const modeTxt = !isEmpty(selectedRow) ? "Edit" : "Add";

  const selectedOption = (string, options) => {
    return options.find((option) => {
      return option.id === string;
    });
  };

  const handleOptionSelect = (option, id) => {
    const updatedState = cloneDeep(addPermssionData);
    set(updatedState, id, option.id);
    setAddPermssionData(updatedState);
  };

  const handleSelectChange = (selectedValues) => {
    const updatedState = cloneDeep(addPermssionData);
    set(updatedState, "roleId", selectedValues?.value);
    set(updatedState, "path", selectedValues?.label);
    setAddPermssionData(updatedState);
    setSelectedRoles(selectedValues);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const updatedState = cloneDeep(addPermssionData);
    set(updatedState, `userAccess.${name}`, checked);
    setAddPermssionData(updatedState);
  };

  const handleSubmit = async () => {
    const newErrorMessages = {};
    const REQUIRED_FIELDS = PERMISSION_REQUIRED_FIELDS;
    REQUIRED_FIELDS.forEach((field) => {
      if (
        !get(addPermssionData, field) ||
        isEmpty(get(addPermssionData, field))
      ) {
        newErrorMessages[field] = "This field is required.";
      }
      if (
        !get(addPermssionData.userAccess, "read") &&
        !get(addPermssionData.userAccess, "write") &&
        !get(addPermssionData.userAccess, "delete")
      ) {
        newErrorMessages["userAccess"] = "This field is required.";
      }
    });

    setErrorMessages(newErrorMessages);
    if (Object.values(newErrorMessages).some((errorMsg) => errorMsg !== "")) {
      return;
    }

    const action = !isEmpty(selectedRow) ? "edit" : "";
    let payload;
    if (action === "edit") {
      payload = {
        moduleName: addPermssionData.moduleName,
        roleId: selectedRoles.value,
        roleName: selectedRoles.label,
        userAccess: addPermssionData.userAccess,
      };
    } else {
      payload = addPermssionData;
    }
    onAddPermssionSubmit(payload, action);
  };

  useEffect(() => {
    if (modeTxt === "Edit") {
      const selectedRoles = roleList?.find(
        (item) => addPermssionData?.roleId === item.value
      );
      if (selectedRoles) {
        setSelectedRoles(selectedRoles);
      }
    }
  }, []);

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={togglePermissionPopup}
        contentLabel="Example Modal"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        className="bg-white p-6 rounded shadow-lg w-4/12"
      >
        <div className="">
          <span className="text-xl font-small pt-3.5">{modeTxt} Permssion</span>
          <div className="grid grid-cols-2 gap-2 pt-3.5">
            <div className="">
              <Dropdown
                options={MODULES_LIST ?? []}
                selectedOption={selectedOption(
                  get(addPermssionData, "moduleName"),
                  MODULES_LIST
                )}
                onOptionSelect={handleOptionSelect}
                label="Permission"
                name="Permssion"
                id="moduleName"
                required={true}
              />
              {errorMessages.moduleName && (
                <p className="text-red-500 text-[12px] mt-1">
                  {errorMessages.moduleName}
                </p>
              )}
            </div>
            <div className="">
              <p className="block text-black text-[12px]">
                Role
                <span className="text-red-500 text-[12px] font-extrabold">
                  *
                </span>
              </p>
              <Select
                options={roleList}
                value={selectedRoles}
                name="roleId"
                placeholder="Select roles..."
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    fontSize: "12px",
                    borderColor: "rgb(156 163 175)",
                  }),
                  option: (baseStyles, state) => ({
                    ...baseStyles,
                    fontSize: "12px",
                  }),
                }}
                onChange={handleSelectChange}
                isSearchable={true}
                required={true}
              />
              {errorMessages.roleId && (
                <p className="text-red-500 text-[12px] mt-1">
                  {errorMessages.roleId}
                </p>
              )}
            </div>
            <div className="">
              <p className="block text-black text-[12px]">
                Access
                <span className="text-red-500 text-[12px] font-extrabold">
                  *
                </span>
              </p>
              <div className="ml-1">
                <Checkbox
                  label="Read"
                  checked={get(addPermssionData.userAccess, "read")}
                  onChange={handleCheckboxChange}
                  name="read"
                />
              </div>
              <div className="ml-1">
                <Checkbox
                  label="Write"
                  checked={get(addPermssionData.userAccess, "write")}
                  onChange={handleCheckboxChange}
                  name="write"
                />
              </div>
              <div className="ml-1">
                <Checkbox
                  label="Delete"
                  checked={get(addPermssionData.userAccess, "delete")}
                  onChange={handleCheckboxChange}
                  name="delete"
                />
              </div>
              {errorMessages.userAccess && (
                <p className="text-red-500 text-[12px] mt-1">
                  {errorMessages.userAccess}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="flex gap-2 mt-2">
          <Button
            text="Submit"
            textColor="white"
            bgColor="[#1A91D1]"
            onClick={() => handleSubmit()}
          />
          <Button
            text="Cancel"
            textColor="Black"
            bgColor="White"
            onClick={() => togglePermissionPopup()}
          />
        </div>
      </Modal>
    </div>
  );
};

export default AddPermissionPopup;
