import React, { useState, useEffect, useRef } from "react";
import { FaAngleDown } from "react-icons/fa";
import InputField from "../InputField";

const OrderDropdown = ({
  options,
  required = false,
  selectedOption = "",
  onOptionSelect,
  label,
  parentClass = "",
  id,
  height = "200px",
  disabled = false,
  alignment = "text-left",
  searchInput = false,
  // resetKey,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [optionsList, setOptionsList] = useState([]);
  const [originalOptionsList, setOriginalOptionsList] = useState([]);
  const [showSearchInput, setShowSearchInput] = useState(searchInput);
  const [searchText, setSearchText] = useState("");
  const [searchErrorMessage, setSearchErrorMessage] = useState("");
  const dropdownRef = useRef(null);

  const handleOptionClick = (option) => {
    console.log("newSelect option:", option);
    console.log("newDropdown ID:", id);

    onOptionSelect(option, id);

    setIsOpen(false);

    setSearchText("");
    setSearchErrorMessage("");
    setOptionsList(originalOptionsList);
  };
  const handleInputChange = (e) => {
    const { value } = e.target;
    setSearchText(value);
    const updatedOptions = originalOptionsList.filter((i) => {
      const searchableName = i?.productName || i?.name || "";
      return searchableName.toLowerCase().includes(value.trim().toLowerCase());
    });

    setOptionsList(updatedOptions.length > 0 ? updatedOptions : []);
    setSearchErrorMessage(
      updatedOptions.length > 0 ? "" : "No Options Matched"
    );
  };

  

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // console.log("Dropdown Options:", options);
    if (options.length > 10) {
      setShowSearchInput(true);
    }
    setOptionsList(options);
    setOriginalOptionsList(options);
  }, [options]);
  useEffect(() => {
    // console.log("axlesType" + options?.axlesMake);
    console.log("Dropdown Options Debug:", options);
  }, [options]);
  useEffect(() => {
    console.log("Selected Option Debug:", selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  function getIsProduct(option,selectedOption){
    if(option.productId != undefined && option.productName != undefined && selectedOption.productId != undefined && selectedOption.productName != undefined){
     return (option.productId === selectedOption.productId &&
        option.productName === selectedOption.productName)
    }else {
      return false;
    }
  }

  function getIsMakeProduct(option,selectedOption){
    if(option.name != undefined && selectedOption.name != undefined){
        return (option.name === selectedOption.name) ;
    }
  }
  const isMatch = (option) => {
    if (!option || !selectedOption) return false;
    return (
     getIsMakeProduct(option,selectedOption) ||
      getIsProduct(option,selectedOption)
    );
  };
 let selectedOptionObject;
 try{
  if(id == 'suspensionType' ){
    console.log(id);
  }
  selectedOptionObject = optionsList?.find(isMatch);
 }catch(e){
  selectedOptionObject=null;
 }
 function getProductName(option){
    //alert(JSON.stringify(options));
    console.log(option?.name);
    return option?.productName;
    
  }
if(id == 'suspensionType')
console.log("Select_suspensionType",selectedOptionObject);
  return (
    <div className={`relative ${parentClass}`} ref={dropdownRef}>
      <label className={`block text-base text-black text-[12px] ${alignment}`}>
        {label}{" "}
        {required && (
          <span className="text-red-500 text-[12px] font-extrabold">*</span>
        )}
      </label>
      <button
        className={`whitespace-nowrap overflow-hidden inline-flex items-center justify-between w-full px-4 py-2 text-[12px] bg-white rounded border border-gray-400 ${disabled ? "border-gray-400 text-gray-400" : "border-gray-400"}`}
        onClick={() =>  setIsOpen(!isOpen)}
        // disabled={disabled}
      >
        <span
          className="flex overflow-hidden w-[93%]"
          title={
            selectedOptionObject
              ? selectedOptionObject.productName || selectedOptionObject.name
              : "Please Select"
          }
        >
          {selectedOptionObject
            ? selectedOptionObject.productName || selectedOptionObject.name
            : "Please Select"}
        </span>
        <FaAngleDown className="ml-2" />
      </button>
      {isOpen && (
        <div className="absolute z-10 w-full mt-2 bg-white border border-gray-300 rounded-md shadow-lg">
          <ul className="py-2" style={{ maxHeight: "200px", overflow: "auto" }}>
            {showSearchInput && (
              <li key="searchInput" className="px-1 py-1">
                <InputField
                  name="searchInput"
                  placeholder="Search Options"
                  value={searchText}
                  onChange={handleInputChange}
                />
                {searchErrorMessage && (
                  <p className="text-red-500 text-[10px] mt-1 ml-1 text-left">
                    {searchErrorMessage}
                  </p>
                )}
              </li>
            )}
            {optionsList.map((option, index) => (
              <li
                key={index}
                className="px-4 py-2 hover:bg-gray-100" 
                style={{
                  height: "2rem",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
                title={getProductName(option)}
              >
                <button
                  className={`whitespace-nowrap overflow-hidden  w-full text-left focus:outline-none text-[12px] ${option.id === selectedOption ? "font-semibold" : ""}`}
                  onClick={() => handleOptionClick(option, id)}
                  id={id}
                >
                  <span className="flex overflow-hidden w-[93%]">
                    {option?.productName || option?.name}
                  </span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default OrderDropdown;


// const handleInputChange = (e) => {
  //   const { value } = e.target;
  //   setSearchText(value);
  //   const updatedOptions = originalOptionsList.filter(
  //     (i) =>
  //       (i.productName &&
  //         i.productName.toLowerCase().indexOf(value.trim().toLowerCase()) !==
  //           -1) ||
  //       (i.name &&
  //         i.name.toLowerCase().indexOf(value.trim().toLowerCase()) !== -1)
  //   );

  //   if (updatedOptions.length > 0) {
  //     setSearchErrorMessage("");
  //     setOptionsList(updatedOptions);
  //     console.log("updateOptions : " + setOptionsList);
  //   } else {
  //     setOptionsList([]);
  //     setSearchErrorMessage("No Options Matched");
  //     console.log("No option matched:" + setSearchErrorMessage);
  //   }
  // };
  
  // const selectedOptionObject = optionsList.find(
  //   (option) => option.name === selectedOption?.name
  // );