import { configureStore } from '@reduxjs/toolkit';
import chartReducer from '../store/reducers/reducer_dashboard';

const store = configureStore({
  reducer: {
    chart: chartReducer,
  },
});

export default store;
