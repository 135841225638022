import React, { useState, useEffect } from "react";
import { TrashIcon, PencilIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Blocks } from "react-loader-spinner";

import Button from "@/components/Button";
import PaginationTable from "@/components/PaginationTable";
import TableCustomFilter from "@/components/TableCustomFilter";
import DeleteConfirmationModal from "@/components/DeleteConfirmationModal";
import ContentHeader from "@/pages/ContentHeader";
import AddProductStock from "./AddProductIssue";
import { PRDODUCT_HEADER  } from "./Headers";

import { APIService } from "@/services";
import { productApi, categoryApi } from "@/utils/API";
import { HandleExport } from "@/utils/HandleExport";
import { rolePermissionChecker, filterInventryData, addSerialNumbersToList } from "@/utils/CustomFunctions";
import { icon } from "@/utils/AppConstants";
import { APP_URLS } from "@/routes";
import AddProductIssue from "./AddProductIssue";

const MaterialIssue = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [productList, setProductList] = useState(null);
    const [uomList, setUOMList] = useState([{label:'HP',value:'HP'},{label:'Nm',value:'Nm'},{label:'lb-ft', value:'lb-ft'},{value:'liters',label:'liters'},{value: 'CC',label:'CC'}]);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [productListChanged, setProductListChanged] = useState(false)
    const [selectedId, setSelectedId] = useState('');
    const [selectedRow, setSelectedRow] = useState({});

    useEffect(() => {
    });

    const togglePopup = () => {
        setSelectedRow({});
        setIsOpen(!isOpen);
    };

    const toggleDeletePopup = () => {
        setIsOpenDeleteModal(!isOpenDeleteModal);
    };

    const handleDelete = async (id) => {
        setIsLoading(true);
        const deleteUrl = `${productApi.delete}${id}`;
        const response = await APIService.delete(deleteUrl);
        setIsOpenDeleteModal(!isOpenDeleteModal);
        setProductListChanged(!productListChanged);
        setIsLoading(false);
    };

    const modifiedData = (data) => {
        return data.map(item => ({
            value: item.id,
            label: item.name
        }));
    };
    
    const getProduct = async () => {
        setIsLoading(true);
        const response = await APIService.get(productApi.filter);
        //const updatedList = await addSerialNumbersToList(response.productList);
       // setProductList(updatedList);
        setIsLoading(false);
    };

    const getCategoryFilterData = async () => {
        const response = await APIService.get(categoryApi.filterCategory);
        setCategoryNameList(modifiedData(response.categoryList) || []);
        
    };

    useEffect(() => {
        getProduct();
        //getCategoryFilterData();
    }, [productListChanged]);

    const handleActionClick = async (action, rowData) => {
        if (action === "delete") {
            const id = rowData?.id;
            setIsOpenDeleteModal(!isOpenDeleteModal);
            setSelectedId(id);
        } else if (action === "edit") {
            const id = rowData?.id
            setSelectedRow(rowData);
            setIsOpen(true);
            setSelectedId(id);
        }
    };

    const columnsArray = [
        ...PRDODUCT_HEADER,
        {
            Header: "Status",
            accessor: "status",
            width: 5,
            Cell: ({ value, row }) => (
                <div className="flex justify-center">
                    <TrashIcon {...icon} onClick={() => handleActionClick("delete", row.original)} />
                    <PencilIcon {...icon} onClick={() => handleActionClick("edit", row.original)} />
                </div>
            ),
        },
    ];

    const onAddProductSubmit = async (data, action) => {
        let response;
        if (action === "post") {
            response = await APIService.post(productApi.post, data);
        } else if (action === "patch") {
            response = await APIService.patch(`${productApi.patch}${selectedId}`, data);
        }

        if (response.status === 200 || response.status === 201) {
            toast.success("Product saved successfully");
        } else {
            toast.error("Error while saving product!");
            return;
        }

        setIsOpen(!isOpen);
        setProductListChanged(!productListChanged);
    };

    function filterDataFunction(searchInput) {
        const { value } = searchInput?.target
        const filteredOrders = filterInventryData(value, productList);
        if (filteredOrders.length === 0) {
            setProductListChanged(!productListChanged);
        } else {
            setProductList(filteredOrders ?? []);
        }
    };

    return (
        <>
            {isLoading ? (
                <div className="flex items-center justify-center">
                    <Blocks
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        visible={true}
                    />
                </div>
            ) : (
                <>
                    <ContentHeader title="Inventory" description="Inventory" showHeader={true} showDatePicker={false} showSearchInput={false} showButton={false} />
                    <div className="m-4 flex flex-col rounded h-auto bg-white">
                        {isOpen && <AddProductStock togglePopup={togglePopup} isOpen={isOpen} uomList={uomList} onAddProductSubmit={onAddProductSubmit} selectedRow={selectedRow} />}
                        {isOpenDeleteModal && selectedId && <DeleteConfirmationModal handleDelete={handleDelete} isOpen={isOpenDeleteModal} togglePopup={toggleDeletePopup} id={selectedId} />}
                        <div className="flex justify-between items-center mt-4 ml-3 mr-5">
                            <div className="flex space-x-3">
                                <TableCustomFilter filterDataFunction={filterDataFunction} />
                            </div>
                            <div className="flex">
                                <div className="pr-[2px]">
                                    <Button text="Export to Excel" icon="FaDownload" textColor="white" bgColor="[#39ACE4]" onClick={() => { HandleExport(columnsArray, productList, "InventoryList") }} />
                                </div>
                                <div>
                                    {<Button text="Add Stock" icon="FaPlus" textColor="white" bgColor="[#39ACE4]" onClick={togglePopup} />}
                                </div>
                            </div>
                        </div>
                        {productList && <PaginationTable columnsData={columnsArray} rowData={productList} />}
                    </div>
                </>
            )}
        </>
    );
}

export default MaterialIssue;
