import React, { useState } from "react";

const UploadingLoader = ({onClick,progress,isShowButton}) => {

  // Simulating upload progress for demonstration
  // const startUpload = () => {
  //   let currentProgress = 0;
  //   const interval = setInterval(() => {
  //     currentProgress += 10;
  //     setProgress(currentProgress);

  //     if (currentProgress >= 100) {
  //       clearInterval(interval);
  //     }
  //   }, 300); // Simulates upload progress every 300ms
  // };

  return (
    <div className="flex flex-col items-center justify-center p-0 w-auto h-auto border border-gray-300 rounded-lg shadow-md">
      {/* Progress Bar */}
         {/* Progress Text */}
    { (!isShowButton) && <>  <div className="relative w-full h-4 bg-gray-200 rounded-full overflow-hidden mb-4">
        <div
          className="h-full bg-green-500 transition-all duration-300"
          style={{ width: `${progress}%` }}
        ></div>
      </div>

   
      <p className="text-gray-700 text-sm mb-4">
        {progress < 100 ? `Uploading... ${progress}%` : "Upload Complete!"}
      </p> </> }

      {/* Start Upload Button */}
     {isShowButton && <button
        onClick={onClick}
        className="px-4 py-2 bg-blue-500 text-white text-sm font-medium rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
      >
        Save 
      </button>}
    </div>
  );
};

export default UploadingLoader;
