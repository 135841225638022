import { 
    HomeIcon,
    UserIcon,
    UserPlusIcon,
    Squares2X2Icon,
    RectangleStackIcon,
    ShieldCheckIcon,
    CalculatorIcon,
    UserCircleIcon,
    DocumentIcon,
    Cog8ToothIcon,
    PowerIcon,
    DocumentDuplicateIcon,
    ShoppingCartIcon,
    QueueListIcon,
    SwatchIcon
} from "@heroicons/react/24/solid";
import { FaChevronRight } from "react-icons/fa";
import { GiPencilRuler } from "react-icons/gi";
import { MdSettingsSuggest, MdCategory } from "react-icons/md";
import { APP_URLS } from ".";
const icon = { className: "w-5 h-5 text-inherit" };

const SideMenuRoutes = [
    {
        layout: "dashboard",
        pages: [
            {
                icon: <Squares2X2Icon {...icon} />,
                name: "Dashboard",
                path: `${APP_URLS.APP_ROOT}/${APP_URLS.HOME}`,
                allowedTo: "Dashboard"
            },
            {
                icon: <Squares2X2Icon {...icon} />,
                name: "User Management",
                path: `${APP_URLS.APP_ROOT}/${APP_URLS.USR_ACCT}`,
                allowedTo: "User Management"
            },
            {
                icon: <UserIcon {...icon} />,
                name: "Sales Department",
                path: `${APP_URLS.APP_ROOT}/${APP_URLS.SALES_ENQ_LIST}`,
                allowedTo: "Sales Department",
                nestedRoutes: [
                    {
                        icon: <FaChevronRight {...icon} />,
                        name: "Sales Enquiry",
                        path: `${APP_URLS.SALES_ENQ_LIST}`,
                        allowedTo: "Sales Enquiry",
                    },
                    {
                        icon: <FaChevronRight {...icon} />,
                        name: "Sales Work Order",
                        path: `${APP_URLS.SALES_WO_LIST}`,
                        allowedTo: "Sales Work Order",
                    },
                    {
                        icon: <FaChevronRight {...icon} />, //TODO FOR SALES
                        name: "Order Receipts",
                        path: `${APP_URLS.SALES_WO_PAYMENT_LIST}`,
                        allowedTo: "Order Receipts",
                    },
                    {
                        
                        icon: <FaChevronRight {...icon} />, //TODO FOR SALES
                        name: "Cash Book",
                        path: `${APP_URLS.SALES_CASHBOOK_VIEW}`,
                        allowedTo: "Sales Work Order",
                    }
                ]
            },
            {
                icon: <GiPencilRuler {...icon} />,
                name: "Design Department",
                path: `${APP_URLS.APP_ROOT}/${APP_URLS.DESN_DEPT}`,
                allowedTo: "Design Department",
            },
            {
                icon: <RectangleStackIcon {...icon} />,
                name: "Production Department",
                path: `${APP_URLS.APP_ROOT}/${APP_URLS.PROD_DEPT}`,
                allowedTo: "Production Department"
            },
            {
                icon: <ShieldCheckIcon {...icon} />,
                name: "Quality and Dispatch",
                path: `${APP_URLS.APP_ROOT}/${APP_URLS.QLTY_DISPATCH}`,
                allowedTo: "Quality Dispatch"
            },
            {
                icon: <CalculatorIcon {...icon} />,
                name: "Account Department",
                path: `${APP_URLS.APP_ROOT}/${APP_URLS.ACCT_DEPT}`,
                allowedTo: "Account Department",
                nestedRoutes: [
                    {
                        icon: <FaChevronRight {...icon} />,
                        name: "Add Payment",
                        path: `${APP_URLS.ACC_DEPT_ADD_PAY}`,
                        allowedTo: "Add Payment",
                    },
                    {
                        icon: <FaChevronRight {...icon} />,
                        name: "Sales Balance",
                        path: `${APP_URLS.ACC_DEPT_SALES_BALANCE_INFO}`,
                        allowedTo: "Sales Balance",
                    }
                ]
            },
            {
                icon: <UserCircleIcon {...icon} />,
                name: "Admin Approval",
                path: `${APP_URLS.APP_ROOT}/${APP_URLS.ADMIN_WO_LIST}`,
                allowedTo: "Work Order Approval",
                nestedRoutes: [
                    {
                        icon: <FaChevronRight {...icon} />,
                        name: "Work Order",
                        path: `${APP_URLS.ADMIN_WO_LIST}`,
                        allowedTo: "Work Order Approval",
                    },
                ]
            },
            // {
            //     icon: <DocumentDuplicateIcon {...icon} />,
            //     name: "Quotation Templates",
            //     path: `${APP_URLS.APP_ROOT}/${APP_URLS.ADMIN_QUO_TEMP_LIST}`,
            //     allowedTo: "Admin Roles"
            // },
            {
                icon: <DocumentIcon {...icon} />,
                name: "Reports",
                path: `${APP_URLS.APP_ROOT}/${APP_URLS.REPORT}`,
                allowedTo: "Reports",
            },
            {
                icon: <UserIcon {...icon} />,
                name: "Inventory",
                path: `${APP_URLS.APP_ROOT}/${APP_URLS.INVEN_PROD_LIST}`,
                allowedTo: "Masters",
                nestedRoutes:[
                    {
                        icon: <FaChevronRight {...icon} />,
                        name: "Add Inventory",
                        path: `${APP_URLS.INVEN_PROD_LIST}`,
                        allowedTo: "Add Inventory",
                    },
                    {
                        icon: <FaChevronRight {...icon} />,
                        name: "Add Stock",
                        path: `${APP_URLS.INVEN_STOCK_LIST}`,
                        allowedTo: "Add Inventory",
                    },
                    {
                        icon: <FaChevronRight {...icon} />,
                        name: "Issue Stock",
                        path: `${APP_URLS.INVEN_ISSUE_STOCK}`,
                        allowedTo: "Add Inventory",
                    }
                ]
            },
            {
                icon: <ShoppingCartIcon {...icon} />,
                name: "Masters",
                path: `${APP_URLS.APP_ROOT}/${APP_URLS.CATEGORY}`,
                allowedTo: "Masters",
                nestedRoutes: [
                    {
                        icon: <QueueListIcon {...icon} />,
                        name: "Category",
                        path: `${APP_URLS.CATEGORY}`,
                        allowedTo: "Category",
                    },
                    {
                        icon: <SwatchIcon {...icon} />,
                        name: "Products",
                        path: `${APP_URLS.PRODUCT}`,
                        allowedTo: "Product",
                    },
                ]
            },
            {
                icon: <Cog8ToothIcon {...icon} />,
                name: "Settings",
                path: `${APP_URLS.APP_ROOT}/${APP_URLS.SETTGS}`,
                allowedTo: "Settings",
            },
            // {
            //     icon: <PowerIcon {...icon} />,
            //     name: "Logout",
            //     path: "",
            //     allowedTo: "All",
            // },
        ],
    },
];

export default SideMenuRoutes;
