import React, { useState } from "react";
import Modal from "react-modal";
import { set, cloneDeep, isEmpty, get } from "lodash";

import InputField from "@/components/InputField";
import Button from "@/components/Button";
import {
  INITIAL_PWD_FORM_VALUE,
  PWD_REQUIRED_FIELDS,
  PWD_ERROR_MESSAGE_VALUES,
} from "./Constant";

Modal.setAppElement("#root");

const ChangePasswordPopup = ({
  togglePasswordPopup,
  isOpen,
  onChangePasswordSubmit,
  selectedRow,
}) => {
  const [passwordData, setPasswordData] = useState(INITIAL_PWD_FORM_VALUE);
  const [errorMessages, setErrorMessages] = useState(PWD_ERROR_MESSAGE_VALUES);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedState = cloneDeep(passwordData);
    set(updatedState, name, value);
    setPasswordData(updatedState);
  };

  const handleSubmit = async () => {
    const newErrorMessages = {};
    const requiredFields = PWD_REQUIRED_FIELDS;
    requiredFields.forEach((field) => {
      if (!get(passwordData, field)) {
        newErrorMessages[field] = "This field is required.";
      } else {
        newErrorMessages[field] = "";
      }
    });

    setErrorMessages(newErrorMessages);
    if (Object.values(newErrorMessages).some((errorMsg) => errorMsg !== "")) {
      return;
    }

    let payload = {
      newPassword: passwordData?.password,
      userId: selectedRow?.userId,
    };
    onChangePasswordSubmit(payload);
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={togglePasswordPopup}
        contentLabel="Example Modal"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        className="bg-white p-6 rounded shadow-lg"
      >
        <div className="">
          <span className="text-xl font-small pt-3.5 ">Change Password</span>
          <div className="grid grid-cols-2 gap-2 pt-3.5 ">
            <div className="mb-2">
              <InputField
                name="password"
                label="New Password"
                placeholder="Enter Password"
                value={passwordData.password}
                onChange={handleInputChange}
              />
              {errorMessages.password && (
                <p className="text-red-500 text-[12px] mt-1">
                  {errorMessages.password}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="flex gap-2">
          <Button
            text="Update"
            textColor="white"
            bgColor="[#1A91D1]"
            onClick={() => handleSubmit()}
          />
          <Button
            text="Cancel"
            textColor="Black"
            bgColor="White"
            onClick={() => togglePasswordPopup()}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ChangePasswordPopup;
