import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@material-tailwind/react";

import { useMaterialTailwindController } from "../../context/MaterialTailwindProvider";
import CustomScrollbarWrapper from "@/components/CustomScrollbarWrapper";
import { usePermission } from "@/hooks/useRolePermission";
import "./Sidebar.css";

const Sidebar = ({ routes }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { permissions } = usePermission();
    const [controller, dispatch] = useMaterialTailwindController();
    const [isActive, setIsActive] = useState(sessionStorage.getItem("isActiveMenu") || "Dashboard");
    const [isNestedActive, setIsNestedActive] = useState("")
    const { sidenavColor, sidenavType, openSidenav } = controller;
    const sidenavTypes = {
        dark: "bg-[#39ACE4]",
        white: "bg-white shadow-lg",
        transparent: "bg-transparent",
    };

    const handleClick = (e, layout, path, name) => {
        if (name === "Sales Department") {
            setIsNestedActive("Enquire");
        }
        if (name === "Admin Approval") {
            setIsNestedActive("Work Order");
        }
        if (name === "Masters") {
            setIsNestedActive("Category");
        }
        if(name === "Inventory") {
        setIsNestedActive("Add Inventory");
        }
        if(name === "Account Department") {
        setIsNestedActive("Add Payment");
        }
        setIsActive(name);
        sessionStorage.setItem("isActiveMenu", name);
        navigate(path);
    }

    const handleNestedClick = (e, layout, path, name) => {
        e.stopPropagation();
        setIsNestedActive(name);
        navigate(`/${layout}/${path}`);
    }

    useEffect(() => {
        let activeNestedItem = ""
        let pathName = location.pathname;

        if (
            pathName.includes("/dashboard/SalesDepartment/Enquiry") ||
            pathName.includes("/dashboard/SalesDepartment/EnquireQuotation") ||
            pathName.includes("/dashboard/SalesDepartment/EnquireQuotationList") ||
            pathName.includes("/dashboard/ViewQuotation")
        ) {
            activeNestedItem = "Sales Enquiry";
        } else if (
            pathName.includes("dashboard/SalesDepartment/WorkOrderList") ||
            pathName.includes("dashboard/SalesDepartment/ViewWorkOrderList") ||
            pathName.includes("dashboard/ViewWorkOrder") ||
            pathName.includes("dashboard/EditWorkOrder")
        ) {
            activeNestedItem = "Sales Work Order";
        } else if (
            pathName.includes("dashboard/SalesDepartment/ViewWorkOrderPaymentList") || 
            pathName.includes("dashboard/SalesDepartment/ViewWorkOrderReceiptList") ||
            pathName.includes("dashboard/SalesDepartment/ViewWorkOrderReceipt")
        ) {
            activeNestedItem = "Order Receipts";
        } else if (pathName.includes("dashboard/AccountDepartment/PaymentList")) {
            activeNestedItem = " ";
        } else if (pathName.includes("dashboard/AccountDepartment/AddPayment")) {
            activeNestedItem = "Add Payment";
        } else if (pathName.includes("dashboard/AccountDepartment/SalesBalanceInformation")) {
            activeNestedItem = "Sales Balance";
        } else if (pathName.includes("dashboard/AdminDepartment/WorkOrderList")) {
            activeNestedItem = "Work Order";
        } else if (pathName.includes("dashboard/Masters/Category")) {
            activeNestedItem = "Category";
        } else if (pathName.includes("dashboard/Masters/Product")) {
            activeNestedItem = "Products";
        } else if (pathName.includes("dashboard/Home")) {
            setIsActive("Dashboard");
            sessionStorage.setItem("isActiveMenu", "Dashboard");
        }

        if (activeNestedItem) {
            setIsNestedActive(activeNestedItem);
        }
    });

    return (
        <aside
            className={`${sidenavTypes[sidenavType]} ${openSidenav  ? "translate-x-0" : "-translate-x-80"} sidebar fixed inset-0 z-50 w-64 transition-transform duration-300 xl:translate-x-0 overflow-y-auto`}
        >
            <CustomScrollbarWrapper
                style={{ width: "100%", height: "calc(100vh - 32px)" }}
                customClass={"custom-scrollbar-thumb"}
            >
                <div className={`relative ${sidenavType ? "border-white/20" : "border-blue-gray-50"}`}>
                    <Link to="/" className="flex items-center gap-4 px-8">
                        <img
                            src="/images/autocraft_trailers_white.png"
                            className="width-221 height-28 py-4"
                        />
                    </Link>
                </div>
                <div className="">
                    { routes.map(({ layout, title, pages }, key) => (
                        <ul key={key} className="mb-4 flex flex-col gap-1 nav-items mx-4" id="devSideNav">
                            {title && (
                                <li className="mx-3.5 mt-4 mb-2">
                                    <Typography
                                        variant="small"
                                        color={sidenavType === "dark" ? "white" : "blue-gray"}
                                        className="font-black opacity-75"
                                    >
                                        {title}
                                    </Typography>
                                </li>
                            )}
                            { pages.map(({ icon, name, path, nestedRoutes = [] }) => (
                                <li key={name} onClick={(e) => handleClick(e, layout, path, name,)} className={`flex flex-col ${isActive === name ? "active" : ""}`} >
                                    <span
                                        className={`flex cursor-pointer hover:bg-[#FFF] hover:text-black hover:rounded-lg gap-4 py-2 px-2 pl-8 ${isActive === name ? "bg-[#fff] text-black rounded-lg" : "border-b border-gray-100 text-white"}`}
                                    >
                                        {icon}
                                        <span className="text-[14px] font-[500] leading-17.5">{name}</span>
                                    </span>
                                    {
                                        nestedRoutes.length > 0 &&
                                        <ul key={key} className="mb-4 flex flex-col gap-1 sideNavCustom" style={{ display: isActive === name ? "block" : "none" }} id="devSideNav">
                                            {title}
                                            {nestedRoutes.map(({ icon, name, path, nestedRoutes = [] }) => (
                                                <li key={name} onClick={(e) => handleNestedClick(e, layout, path, name)}>
                                                    <span className={`flex gap-4 py-2 px-2 pl-16 cursor-pointer ${isNestedActive === name ? "text-white" : "text-[#C2C2C2]"}`}>
                                                        {icon}
                                                        <span className="text-[14px] font-[500] leading-17.5">{name}</span>
                                                    </span>
                                                </li>
                                            ))}
                                        </ul>
                                    }
                                </li>
                            ))}
                        </ul>
                    ))}
                </div>
            </CustomScrollbarWrapper>
        </aside>
    )
}

export default Sidebar;
