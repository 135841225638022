import { createContext, useState, useEffect } from "react";

import { useAuth } from "@/hooks/useAuth";
import {
  organizationApi,
  getOrderDataApi,
  productInventoryApi,
  productionStatusApi,
} from "@/utils/API";
import { getDropdownOptions } from "@/utils/CustomFunctions";
import { APIService } from "@/services";
import { APP_URLS } from "@/routes";

export const DataContext = createContext();

const DataProvider = ({ children }) => {
  const { user } = useAuth();
  const [data, setData] = useState({});
  const [isFetching, setIsFetching] = useState(false);

  const fetchData = async () => {
    setIsFetching(true);
    const orgResponse = await APIService.get(`${organizationApi.get}`);
    const orderformResponse = await APIService.get(getOrderDataApi.get);
    const orderForm = await getDropdownOptions(orderformResponse);
    console.log("mera wala :", orderForm);
    const inventoryResponse = await APIService.get(productInventoryApi.get);
    const statusResponse = await APIService.get(`${productionStatusApi.get}`);
    const data = {
      orderForm,
      organization: orgResponse,
      productInventory: inventoryResponse.productInventoryList,
      orderStatus: statusResponse.productionStatusList || [],
    };
    setData((prevState) => ({
      ...prevState,
      ...data,
    }));
    setIsFetching(false);
  };

  const refreshData = async () => {
    await fetchData();
  };

  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [user]);

  return (
    <DataContext.Provider value={{ data, isFetching, refreshData }}>
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
