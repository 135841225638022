  // export const API_HOST_URI ="http://testing-autocraft-env.eba-c88nfd3v.ap-south-1.elasticbeanstalk.com"; //TESTING
export const API_HOST_URI =
  "http://autocraft-backend-env-1.eba-uvutrned.ap-south-1.elasticbeanstalk.com"; //PRODUCTION

export const orderApi = {
  get: `${API_HOST_URI}/query/order/`,
  post: `${API_HOST_URI}/order/`,
  delete: `${API_HOST_URI}/order/`,
  patch: `${API_HOST_URI}/order/`,
  //getParentsOrder: `${API_HOST_URI}/query/order/getParentOrders`,
  generateChassis: `${API_HOST_URI}/order/generateChassis/`,
  filter: `${API_HOST_URI}/query/order/filter`,
  getParentOrders: `${API_HOST_URI}/query/order/filter?filterAnd=parentOrder%7Cisnull%26status%7Ceq%7CApproved&limit=100000&offset=0&order=DESC&orderBy=lastUpdated`,
  getParentOrdersByUsername: (username) => {
    return `${API_HOST_URI}/query/order/filter?filterAnd=parentOrder%7Cisnull%26createdBy%7Ceq%7C${username.toLowerCase()}&limit=100000&offset=0&order=DESC&orderBy=lastUpdated`;
  },
  getSuborders: (parentOrderId) => {
    return `${API_HOST_URI}/query/order/filter?filterAnd=parentOrder%7Ceq%7C${parentOrderId}&limit=1000&offset=0&order=DESC&orderBy=lastUpdated`;
  },
  getSubordersByUsername: (parentOrderId, username) => {
    return `${API_HOST_URI}/query/order/filter?filterAnd=parentOrder%7Ceq%7C${parentOrderId}%26createdBy%7Ceq%7C${username.toLowerCase()}&limit=1000&offset=0&order=DESC&orderBy=lastUpdated`;
  },
  getParentOrdersIdByUsername: (username) => {
    return `${API_HOST_URI}/query/order/filter?fields=id,orderId,createdBy,customerName&filterAnd=parentOrder%7Cisnull%26createdBy%7Ceq%7C${username.toLowerCase()}%26status%7Ceq%7CApproved&limit=100000&offset=0&order=DESC&orderBy=lastUpdated`;
  },
};

export const pdfGenerator = {
  post: `${API_HOST_URI}/pdfGenerator/`,
};

export const accountApi = {
  post: `${API_HOST_URI}/accountMgmt/`,
  delete: `${API_HOST_URI}/accountMgmt/`,
  patch: `${API_HOST_URI}/accountMgmt/`,
  get: `${API_HOST_URI}/query/account`,
  getById: `${API_HOST_URI}/query/account/`,
  filter: `${API_HOST_URI}/query/account/filter`,
  getAllPayment: () => {
    return `${API_HOST_URI}/query/account?limit=1000000&offset=0&order=DESC&orderBy=lastUpdated`;
  },
  getAllPaymentBySales: (username) => {
    return (
      `${API_HOST_URI}/query/account/filter?filterAnd=salesPersonId%7Ceq%7C` +
      username.trim().toLowerCase() +
      `&limit=1000000&offset=0&order=DESC&orderBy=lastUpdated`
    );
  },
  getBalanceInfo: `${API_HOST_URI}/query/account/getBalanceInfo`,
};

export const categoryApi = {
  get: `${API_HOST_URI}/query/category/`,
  delete: `${API_HOST_URI}/category/`,
  post: `${API_HOST_URI}/category/`,
  patch: `${API_HOST_URI}/category/`,
  getCategoryByName: (name) => {
    return `${API_HOST_URI}/query/category/filter?fields=name%2CsubCategories&filterAnd=name%7Ceq%7C${name}&order=DESC&orderBy=lastUpdated`;
  },
  filter: `${API_HOST_URI}/query/category/filter?limit=1000&offset=0&order=DESC&orderBy=createdOn`,
  filterCategory: `${API_HOST_URI}/query/category/filter?limit=1000&offset=0&fields=name,subCategories&order=DESC&orderBy=lastUpdated`, //only name field
};

export const permissionApi = {
  get: `${API_HOST_URI}/query/user-permission`,
  post: `${API_HOST_URI}/user-permission/`,
  patch: `${API_HOST_URI}/user-permission/`,
  delete: `${API_HOST_URI}/user-permission/`,
  filter: `${API_HOST_URI}/query/user-permission/filter?order=DESC&orderBy=lastUpdated`,
};

export const userApi = {
  get: `${API_HOST_URI}/query/user/`,
  post: `${API_HOST_URI}/user/`,
  delete: `${API_HOST_URI}/user/`,
  patch: `${API_HOST_URI}/user/`,
  offline: `${API_HOST_URI}/user/offline/`,
  filter: `${API_HOST_URI}/query/user/filter?limit=2000&offset=0&order=DESC&orderBy=createdOn`,
  fetchUserById: `${API_HOST_URI}/query/user/filter`,
  filterUserList: `${API_HOST_URI}/query/user/filter?fields=id,familyName,givenName,middleName,userId,roleId&limit=3000&offset=0&order=DESC&orderBy=createdOn`,
};

export const userRoleApi = {
  get: `${API_HOST_URI}/query/role/`,
  post: `${API_HOST_URI}/role/`,
  delete: `${API_HOST_URI}/role/`,
  patch: `${API_HOST_URI}/role/`,
  filter: `${API_HOST_URI}/query/role/filter?limit=2000&offset=0&order=DESC&orderBy=createdOn`,
  filterRole: `${API_HOST_URI}/query/role/filter`,
};

export const loginServiceApi = {
  post: `${API_HOST_URI}/user/login/`,
  resetPassword: `${API_HOST_URI}/user/resetPassword/`,
  resetPasswordSendOTP: `${API_HOST_URI}/user/resetPasswordSendOTP/`,
  resetPasswordValidateOTP: `${API_HOST_URI}/user/resetPasswordValidateOTP/`,
};

export const offlineServiceApi = {
  post: `${API_HOST_URI}/user/offline/`,
};

export const verifyUserServiceApi = {
  post: `${API_HOST_URI}/user/validateUser/`,
};

// its our dropdown data api
export const getOrderDataApi = {
  get: `${API_HOST_URI}/order/getOrderForm`,
};

export const productApi = {
  get: `${API_HOST_URI}/query/product/`,
  delete: `${API_HOST_URI}/product/`,
  post: `${API_HOST_URI}/product/`,
  filterByTrailerType: `${API_HOST_URI}/query/product/filter?filterAnd=categoryId%7Ceq%7C01&order=DESC&orderBy=lastUpdated`,
  filter: `${API_HOST_URI}/query/product/filter?limit=100000&offset=0&order=DESC&orderBy=createdOn`,
  patch: `${API_HOST_URI}/product/`,
};

export const productInventoryApi = {
  get: `${API_HOST_URI}/query/inventory-product/`,
  delete: `${API_HOST_URI}/product_inventory/`,
  post: `${API_HOST_URI}/product_inventory/`,
  filterByTrailerType: `${API_HOST_URI}/query/product/filter?filterAnd=categoryId%7Ceq%7C01&order=DESC&orderBy=lastUpdated`,
  filter: `${API_HOST_URI}/query/inventory-product/filter?limit=100000&offset=0&order=DESC&orderBy=createdOn`,
  patch: `${API_HOST_URI}/product_inventory/`,
};

export const stockInventoryApi = {
  get: `${API_HOST_URI}/query/productInventoryStock/`,
  delete: `${API_HOST_URI}/productInventoryStock/`,
  post: `${API_HOST_URI}/productInventoryStock/`,
  filterByTrailerType: `${API_HOST_URI}/query/product/filter?filterAnd=categoryId%7Ceq%7C01&order=DESC&orderBy=lastUpdated`,
  filter: `${API_HOST_URI}/query/productInventoryStock/filter?limit=100000&offset=0&order=DESC&orderBy=createdOn`,
  patch: `${API_HOST_URI}/productInventoryStock/`,
};

export const organization = {
  get: `${API_HOST_URI}/query/organization/filter?fields=organizationName%2CorganizationSubtitle%2Cid&filterAnd=status%7Ceq%7CInitial&order=DESC&orderBy=lastUpdated`,
};

export const productInventoryType = {
  get: `${API_HOST_URI}/query/inventoryType/`,
  /*   delete: `${API_HOST_URI}/product_inventory/`,
    post: `${API_HOST_URI}/product_inventory/`,
    filterByTrailerType: `${API_HOST_URI}/query/product/filter?filterAnd=categoryId%7Ceq%7C01&order=DESC&orderBy=lastUpdated`,
    filter: `${API_HOST_URI}/query/inventory-product/filter?limit=100000&offset=0&order=DESC&orderBy=createdOn`,
    patch: `${API_HOST_URI}/product_inventory/`, */
};

export const enquireApi = {
  get: `${API_HOST_URI}/query/enquiry/`,
  post: `${API_HOST_URI}/enquiry/`,
  patch: `${API_HOST_URI}/enquiry/`,
  delete: `${API_HOST_URI}/enquiry/`,
  filter: `${API_HOST_URI}/query/enquiry/filter?limit=100000&offset=0&order=DESC&orderBy=createdOn`,
  getEnquiryByUsername: (username) => {
    return (
      `${API_HOST_URI}/query/enquiry/filter?filterAnd=createdBy%7Ceq%7C` +
      username.toLowerCase() +
      `&limit=100000&offset=0&order=DESC&orderBy=createdOn`
    );
  },
};

export const enquireQuatationApi = {
  get: `${API_HOST_URI}/query/quotation/`,
  post: `${API_HOST_URI}/quotation/`,
  patch: `${API_HOST_URI}/quotation/`,
  delete: `${API_HOST_URI}/quotation/`,
};

export const organizationApi = {
  get: `${API_HOST_URI}/query/organization/`,
  post: `${API_HOST_URI}/organization/`,
  patch: `${API_HOST_URI}/organization/`,
  delete: `${API_HOST_URI}/organization/`,
};

export const quotationTemplateApi = {
  get: `${API_HOST_URI}/query/quotationTemplate`,
  post: `${API_HOST_URI}/quotationTemplate/`,
  patch: `${API_HOST_URI}/quotationTemplate/`,
  delete: `${API_HOST_URI}/quotationTemplate/`,
};

export const bomApi = {
  get: `${API_HOST_URI}/bom/getOrderForm/`,
};

export const dashboardApi = {
  get: ` ${API_HOST_URI}/admin/dashboard/dailyOrderCount`,
};

export const materialRequestApi = {
  get: `${API_HOST_URI}/query/materialRequisition`,
  post: `${API_HOST_URI}/materialRequisition/`,
  delete: `${API_HOST_URI}/materialRequisition/`,
  validate: `${API_HOST_URI}/materialRequisition/validate/`,
  filter: `${API_HOST_URI}/query/materialRequisition/filter`,
  getRequestByOrderId: (orderId) => {
    return (
      `${API_HOST_URI}/query/materialRequisition/filter?filterAnd=workOrderId%7Ceq%7C` +
      orderId +
      `&limit=100000&offset=0`
    );
  },
};

export const productionStatusApi = {
  get: `${API_HOST_URI}/query/productionStatus`,
  filter: `${API_HOST_URI}/query/productionStatus/filter`,
};

export const productionApi = {
  get: `${API_HOST_URI}/query/production`,
  post: `${API_HOST_URI}/production/`,
  delete: `${API_HOST_URI}/production/`,
  filter: `${API_HOST_URI}/query/production/filter`,
  getProductionListByOrderId: (orderId) => {
    return `${API_HOST_URI}/query/production/filter?filterAnd=workOrderId%7Ceq%7C${orderId}&limit=1000&offset=0&order=DESC&orderBy=lastUpdated`;
  },
};
